import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { IntlProvider } from 'react-intl'
import AppLocale from 'lang'
import { flattenMessages } from './lang/utils'

import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import 'moment/locale/es'

import NotFound from 'pages/NotFound'
import Dashboard from 'pages/Dashboard'
import Admin from 'pages/Admin'
import Catedra from 'pages/Catedra'
import Clase from 'pages/Clase'

import Layout from 'components/Layout'

function App() {
  const locale = useSelector(({ settings: { locale } }) => locale, shallowEqual)
  const currentAppLocale = AppLocale[locale]

  return (
    <Router>
      <IntlProvider libInstance={moment} locale={currentAppLocale.locale} messages={flattenMessages(currentAppLocale.messages)}>
        <MuiPickersUtilsProvider locale={currentAppLocale.locale.slice(0, 2)} utils={MomentUtils}>
          <Layout>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route path="/admin" component={Admin} />
              <Route exact path={`/catedra/:catedra/:claseId`} component={Clase} />
              <Route path={`/catedra/:catedra`} component={Catedra} />
              <Redirect to="/error" />
              <Route component={NotFound} />
            </Switch>
          </Layout>
        </MuiPickersUtilsProvider>
      </IntlProvider>
    </Router>
  )
}

export default App

import React from 'react'

import MaterialTable from 'material-table'

import SectionCard from './SectionCard'
import { useTheme } from '@material-ui/core/styles'

export default function StudentReport({ report }) {
  const theme = useTheme()

  const getStateColor = state => {
    return !!state ? theme.palette.auxiliar.darkgreen.regular : theme.palette.auxiliar.orange.regular
  }

  return (
    <MaterialTable
      isLoading={!report.length}
      Toolbar={null}
      columns={[
        {
          title: 'Estudiante',
          field: 'nombre',
          type: 'string',
          cellStyle: { fontFamily: theme.typography.fontFamily },
          render: rowData => `${rowData.nombre} ${rowData.apellido_paterno} ${rowData.apellido_materno}`,
        },
        {
          title: 'Estado',
          field: 'estado',
          type: 'string',
          render: rowData => <span style={{ color: getStateColor(rowData.estado) }}>{rowData.estado ? 'Al Dia' : 'Pendientes'}</span>,
          cellStyle: {
            fontFamily: theme.typography.fontFamily,
          },
        },
        {
          title: 'Detalle',
          field: 'detalles',
          type: 'string',
          render: rowData => (rowData.estado ? 'Esta al dia' : `Debe las siguientes clases: ${rowData.detalles}`),
          cellStyle: { fontFamily: theme.typography.fontFamily },
        },
      ]}
      data={report}
      options={{
        loadingType: 'linear',
        toolbar: false,
        search: false,
        actionsColumnIndex: -1,
        rowStyle: rowData => ({
          backgroundColor: rowData.tableData.id % 2 === 0 ? theme.palette.grey[100] : theme.palette.background.paper,
        }),
        headerStyle: {
          padding: '.5rem',
          fontSize: '.9rem',
          fontFamily: theme.typography.fontFamily,
          fontWeight: '700',
          textTransform: 'uppercase',
          letterSpacing: 0,
          color: theme.text.primary,
        },
      }}
      components={{
        Container: props => (
          //Whenever you use table inside a SectionCard use 'noContentPadding' attr
          <SectionCard noContentPadding cardTitle={'Reporte de Estudiantes'}>
            {props.children}
          </SectionCard>
        ),
      }}
    />
  )
}

import React from 'react'
import { Typography, Paper, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: props => theme.palette.auxiliar[props.backgroundColor].regular,
    color: theme.palette.typography[0],
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
    padding: '10px',
    width: '100%',
    position: 'relative',
    paddingBottom: 30,
    minHeight: 90,
  },
  heading: {
    fontSize: '1rem',
    fontFamily: 'Roboto Condensed',
    // textTransform: 'capitalize',
    textTransform: 'uppercase',
    fontWeight: 700,
    lineHeight: 1,
  },
  content: {
    fontFamily: 'Roboto Condensed',
    lineHeight: 1,
    fontWeight: 700,
    marginTop: 15,
    fontSize: 14,
  },
  footer: {
    fontFamily: 'Roboto Condensed',
    lineHeight: 1,
    fontWeight: 400,
    marginTop: 10,
    fontSize: 12,
    position: 'absolute',
    bottom: 10,
    left: 10,
  },
  link: {
    textDecoration: 'none',
    height: '100%',
    alignSelf: 'stretch',
    flexGrow: 1,
    display: 'flex',
    width: '100%',
  },
}))

const MiniBox = props => {
  const classes = useStyles(props)

  return (
    <Link to={props.linkto || window.location.pathname} className={classes.link}>
      <Paper className={classes.paper}>
        <Typography className={classes.heading} variant="h6" component="h6">
          {props.heading}
        </Typography>
        <Typography className={classes.content} variant="overline">
          {props.content}
        </Typography>
        <Typography className={classes.footer} variant="overline">
          {props.footer}
        </Typography>
      </Paper>
    </Link>
  )
}

MiniBox.defaultProps = {
  backgroundColor: 'blue',
}

export default MiniBox

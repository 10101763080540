import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ClasesTable from 'components/ClasesTable'
import ReporteAccesos from 'components/ReporteAccesos'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import queryString from 'query-string'
import { ADMIN } from '../../helpers/constants'

const Clases = props => {
  const {
    history,
    location: { search },
    clases,
    me: { role_active },
  } = props

  const { fecha, selectedClase } = queryString.parse(search)

  const clase = clases[selectedClase]

  useEffect(() => {
    const query = queryString.stringify({
      fecha: moment()
        .utc()
        .format('YYYY-MM-DD'),
    })

    !fecha &&
      history.push({
        pathname: '',
        search: query,
      })
    // eslint-disable-next-line
  }, [])

  const header = clase && `Entornos ${clase.catedra} ${clase.nombre}`

  return role_active !== ADMIN ? (
    <Redirect to="/" />
  ) : (
    <>
      <ClasesTable {...props} />
      {clase && <ReporteAccesos noDisplayCols={['¿Encender?', 'Rollback']} entornosById clase={clase} {...props} header={header} />}
    </>
  )
}

const mapStateToProps = state => ({ me: state.me, clases: state.clases.byId })

export default connect(
  mapStateToProps,
  null
)(Clases)

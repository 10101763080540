import React from 'react'
import { Route } from 'react-router-dom'

import Clases from './Clases'
import Reports from './Reports'

const Admin = ({ match }) => {
  return (
    <>
      <Route path={`${match.url}/clases`} component={Clases} />
      <Route path={`${match.url}/reports/:report`} component={Reports} />
    </>
  )
}

export default Admin

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Loading from 'components/Loading'

const { REACT_APP_LOADING_IMAGE } = process.env

const useStyles = makeStyles(theme => ({
  '@keyframes fadeAnimation': {
    '0%': { opacity: 0.8, transform: 'scale(0.93)' },
    '50%': { opacity: 1, transform: 'scale(1)' },
    '100%': { opacity: 0.8, transform: 'scale(0.93)' },
  },
  layout: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  logo: {
    width: '22%',
    height: '10%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    animation: '2.3s $fadeAnimation infinite',
  },
  loading: {
    marginBottom: '6%',
  },
}))

const InitLoading = props => {
  const classes = useStyles()

  return (
    <div className={classes.layout}>
      <div className={classes.logo} style={{ backgroundImage: `url(${REACT_APP_LOADING_IMAGE})`, backgroundSize: 'contain' }} />
      <Loading thickness={6} className={classes.loading} />
    </div>
  )
}

export default InitLoading

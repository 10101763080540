import { combineReducers } from 'redux'

import accesos from './accesos'
import catedras from './catedras'
import clases from './clases'
import entornos from './entornos'
import consolas from './consolas'
import me from './me'
import modal from './modal'
import notifyer from './notifyer'
import settings from './settings'
import sidebar from './sidebar'
import reports from './reports'

const reducers = combineReducers({ sidebar, me, clases, entornos, consolas, catedras, accesos, modal, notifyer, settings, reports })

export default reducers

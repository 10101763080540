export default [
  {
    title: 'Alumno',
    field: 'alumno',
  },
  { title: 'Asignatura', field: 'asignatura' },
  { title: 'Seccion', field: 'seccion' },
  {
    title: 'Bonus Tomadas',
    field: 'bonus_tomadas',
  },

  {
    title: 'Horas bonus consumidas',
    field: 'horas_bonus_consumidas',
  },
]

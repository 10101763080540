import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { fetchUser, fetchSidebar, fetchCatedras } from 'redux/actions'
import moment from 'moment'
import { withRouter } from 'react-router'
import { ADMIN } from '../helpers/constants'

// Material
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'

// Component
import Sidebar from 'components/Sidebar'
import NavBar from 'components/NavBar'
import Footer from 'components/Footer'
import ModalDisplay from 'components/ModalDisplay'
import InitLoading from 'components/InitLoading'

// Theme
import themeStyle from '../themeStyle'
import Fade from '@material-ui/core/Fade'

// Notifications
import NotificationsProvider from './NotificationsProvider'
import Notifyer from './Notifyer'

const theme = createMuiTheme(themeStyle)

const { REACT_APP_AUTH } = process.env

const readGet = (o) => {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + o + '=([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')
    ) || null
  )
}

const Layout = (props) => {
  const { history } = props
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  let { auth, user, role_active, token } = useSelector(({ me }) => me, shallowEqual)
  const catedras = useSelector(({ catedras }) => Object.values(catedras.byId), shallowEqual)

  token = readGet('token') || token || sessionStorage.getItem('token')
  if (moment().isAfter(sessionStorage.getItem('tokenExp'))) {
    sessionStorage.clear()
    token = null
    auth = false
  }

  if (!auth && token == null) window.location.href = REACT_APP_AUTH + window.location.origin
  if (!auth && readGet('token') !== null) window.history.replaceState({}, document.title, '/')
  if (!auth && token !== null) dispatch(fetchUser(token))

  useEffect(() => {
    if (!!user.id && !!role_active && role_active !== ADMIN) {
      dispatch(fetchSidebar(user.id, role_active))
      dispatch(fetchCatedras(user.id, role_active))
    }
  }, [user, role_active])

  const handleRoleActiveIsAdmin = () => {
    const adminPath = '/admin'
    const { location: { pathname } } = history
    
    if (!pathname.includes(adminPath)) history.push(adminPath)

    setIsLoading(false)
  }

  const handleRoleActiveIsNotAdmin = (id, role_active) => {
    dispatch(fetchSidebar(id, role_active))
    dispatch(fetchCatedras(id, role_active))
  }

  useEffect(() => {
    if (user.id && role_active) {
      if (role_active === ADMIN) handleRoleActiveIsAdmin()
      else handleRoleActiveIsNotAdmin(user.id, role_active)
    }
  }, [role_active])

  useEffect(() => {
    if (catedras.length) setIsLoading(false)
  }, [catedras])

  return (
    <div style={{ height: '100vh' }}>
      {isLoading ? (
        <ThemeProvider theme={theme}>
          <InitLoading />
        </ThemeProvider>
      ) : (
        <>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <NotificationsProvider>
              <Notifyer />
              <ModalDisplay />
              <Fade in={true} timeout={400}>
                <div>
                  <NavBar />
                  <Grid container direction="row" style={{ height: 'calc(100vh - 66px - 56px)' }}>
                    <Sidebar />
                    <Grid
                      item
                      style={{
                        width: 'calc(100% - 230px)',
                        height: 'calc(100vh - 66px - 56px)',
                        overflow: 'hidden',
                        overflowY: 'scroll',
                        padding: '15px 24px',
                        background: '#f0f0f0',
                      }}
                    >
                      {props.children}
                    </Grid>
                  </Grid>
                  <Footer />
                </div>
              </Fade>
            </NotificationsProvider>
          </ThemeProvider>
        </>
      )}
    </div>
  )
}

export default withRouter(Layout)

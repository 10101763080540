import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { removeSnackbar } from 'redux/actions'
import { useSnackbar } from 'notistack'

import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'

const Notifyer = ({ notifications }) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [displayed, setDisplayed] = useState([])

  const handleClose = key => {
    closeSnackbar(key)
    dispatch(removeSnackbar(key))
  }

  useEffect(() => {
    notifications.length &&
      notifications.forEach(({ key, message, options = {} }) => {
        // Do nothing if snackbar is already displayed
        if (displayed.includes(key)) return

        // Display snackbar using notistack hook

        enqueueSnackbar(message, {
          key,
          ...options,
          action: (
            <IconButton key="close" aria-label="Close" color="inherit" onClick={() => handleClose(key)}>
              <Close />
            </IconButton>
          ),
          onClose: () => {
            // Dispatch action to remove snackbar from redux store
            dispatch(removeSnackbar(key))
          },
        })
        setDisplayed([...displayed, key])
      })
    // eslint-disable-next-line
  }, [notifications, displayed])

  return null
}

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: state.notifyer.notifications,
  }
}
export default connect(
  mapStateToProps,
  null
)(Notifyer)

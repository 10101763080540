import { FETCH_CATEDRAS, UPDATE_CATEDRA } from 'actionTypes'
import { enqueueSnackbar } from 'redux/actions'
import messages from 'messages'

const { REACT_APP_API_END } = process.env
const storeData = (type, data) => ({ type, data })

export const fetchCatedras = (userId, role) => dispatch => {
  fetch(REACT_APP_API_END + `/catedraGetAllByUser?usuario_id=${userId}&usuario_role=${role}`, {
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
    }),
  })
    .then(res => res.json())
    .then(res => {
      dispatch(storeData(FETCH_CATEDRAS, res))
    })
    .catch(err =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.getCatedras,
          options: {
            variant: 'error',
          },
        })
      )
    )
}
//

export const fetchReport = (catedraId, role) => dispatch => {
  fetch(REACT_APP_API_END + `/usuarioGetAllByCatedra?catedra_id=${catedraId}&usuario_role=${role}`, {
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
    }),
  })
    .then(res => res.json())
    .then(res => {
      dispatch(storeData(UPDATE_CATEDRA, { id: catedraId, data: { report: res } }))
    })
    .catch(err =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.getUsuarios,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

const WithAuthorization = (props) => {
  const { roles } = props
  const { role_active } = useSelector(({ me }) => me, shallowEqual)
  return roles.includes(role_active) ? props.children : null
}

export default WithAuthorization

import { CHANGE_LOCALE } from 'actionTypes'
const initialState = {
  locale: 'es-ES',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.data }

    default:
      return state
  }
}

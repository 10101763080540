import React, { useState, useEffect } from 'react'

import MaterialTable from 'material-table'
import { useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  periodBtn: {
    paddingRight: 10,
    color: (props) => (props.isSelected ? theme.palette.grey[600] : theme.palette.grey[400]),
    textTransform: 'capitalize',
    fontWeight: (props) => (props.isSelected ? 600 : 200),
  },
}))

const PeriodBtn = (props) => {
  const { handleClick, dateRangeType, type } = props
  const classes = useStyles({ isSelected: dateRangeType === type })

  return (
    <Typography onClick={() => handleClick(props.type)} className={classes.periodBtn}>
      <FormattedMessage id={`reports.filters.${type}`} />
    </Typography>
  )
}

const ReportTableLayout = (props) => {
  const { endpoint, fetchAction } = props
  const theme = useTheme()
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(moment().format())
  const [endDate, setEndDate] = useState(moment().format())
  const [dateRangeType, setDateRangeType] = useState('')
  const [loading, setLoading] = useState(false)

  const setPresetRange = (type) => {
    const substractArgs = {
      week: [7, 'days'],
      month: [1, 'month'],
      quarter: [3, 'month'],
    }

    setEndDate(moment())
    setStartDate(moment().subtract(...substractArgs[type]))
  }

  useEffect(() => {
    setLoading(true)
    dispatch(
      fetchAction(endpoint, [
        {
          name: 'dates',
          payload: [moment(startDate).format('YYYY-MM-DD HH:mm:ss'), moment(endDate).format('YYYY-MM-DD HH:mm:ss')],
        },
      ])
    )
      .then((data) => setLoading(false))
      .catch((err) => setLoading(false))
  }, [startDate, endDate])

  useEffect(() => {
    !!dateRangeType && setPresetRange(dateRangeType)
  }, [dateRangeType])

  const handleSelectDate = (type, value) => {
    if (type === 'start') setStartDate(value)
    if (type === 'end') setEndDate(value)
    setDateRangeType('')
  }

  return (
    <MaterialTable
      {...props}
      isLoading={loading}
      Toolbar={true}
      options={{
        loadingType: 'linear',
        search: false,
        actionsColumnIndex: -1,
        rowStyle: (rowData) => ({
          backgroundColor: rowData.tableData.id % 2 === 0 ? theme.palette.grey[100] : theme.palette.background.paper,
        }),
        headerStyle: {
          padding: '.5rem',
          fontSize: '.9rem',
          fontFamily: theme.typography.fontFamily,
          fontWeight: '700',
          textTransform: 'uppercase',
          letterSpacing: 0,
          color: theme.text.primary,
        },
      }}
      components={{
        Container: (props) => <div>{props.children}</div>,
        Toolbar: (props) => (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                id="date-picker-inline"
                label="Inicio"
                value={startDate}
                onChange={(e) => handleSelectDate('start', moment(e))}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                margin="normal"
                format="DD/MM/YYYY"
                id="date-picker-inline"
                label="Fin"
                value={endDate}
                onChange={(e) => handleSelectDate('end', moment(e))}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PeriodBtn type="week" dateRangeType={dateRangeType} handleClick={setDateRangeType} />
              <PeriodBtn type="month" dateRangeType={dateRangeType} handleClick={setDateRangeType} />
              <PeriodBtn type="quarter" dateRangeType={dateRangeType} handleClick={setDateRangeType} />
            </div>
          </div>
        ),
      }}
    />
  )
}

export default ReportTableLayout

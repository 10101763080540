import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Typography, AppBar, Paper, Grid } from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@material-ui/core/styles'
import MiniBox from './MiniBox'
moment.locale('es')
moment.updateLocale('es', {
  monthsShort: moment.monthsShort('-MMM-'),
  weekdaysShort: 'dom_lun_mar_mié_jue_vie_sáb'.split('_'),
  weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
})

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 32,
    marginTop: 12,
  },
  cardTitle: {
    margin: '0',
    paddingBottom: 10,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  card: {
    backgroundColor: theme.palette.typography[60],
    height: 90,
    padding: '10% 13%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardNext: {
    backgroundColor: theme.palette.auxiliar.darkgreen.regular,
    height: 90,
    display: 'flex',
    padding: '10% 13%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  classText: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 18,
    color: theme.palette.common.white,
    fontFamily: 'Roboto Condensed',
  },
  classStatus: {
    lineHeight: 1,
    fontSize: '0.8rem',
    paddingLeft: '17%',
    color: theme.palette.common.white,
  },
  classGrid: {
    padding: '13px',
  },
  indicator: {
    height: 4,
  },
  rootTabs: {
    backgroundColor: '#fff',
  },
}))

const TabContainer = ({ children, dir }) => {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  )
}

const splitClasses = (classes) => {
  const bonusClasses = []
  const nextClasses = []

  classes.forEach(({ tipo, ...clase }) => {
    const appendTo = tipo === 'b' ? bonusClasses : nextClasses

    appendTo.push({ tipo, ...clase })
  })
  
  return { bonusClasses, nextClasses }
}

const NextClasses = ({ cardTitle, clases, location }) => {
  const path = location.pathname
  const classes = useStyles()
  const theme = useTheme()
  const [tabIndex, setValue] = React.useState(0)

  const isActive = clase => ['open', 'inProgress'].includes(clase.estado)

  const handleChange = (event, newValue) => setValue(newValue)
  const handleChangeIndex = index => setValue(index)
  const displayDateHours = date =>
    moment
      .utc(date)
      .local()
      .format(process.env.REACT_APP_DATE_FORMAT)

  const { bonusClasses, nextClasses } = splitClasses(clases)

  return (
    <div>
      <Paper className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="primary"
            variant="fullWidth"
            classes={{ indicator: classes.indicator }}
          >
            <Tab label={cardTitle} className={tabIndex === 0 ? classes.rootTabs : ''} />
            <Tab label="Clases Bonus" className={tabIndex === 1 ? classes.rootTabs : ''} />
          </Tabs>
        </AppBar>
        <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={tabIndex} onChangeIndex={handleChangeIndex}>
          <TabContainer dir={theme.direction}>
            <Grid container spacing={3}>
              {nextClasses
                .sort((a, b) => (a.fecha < b.fecha ? -1 : 1))
                .slice(0, process.env.REACT_APP_MINIBOX_LIMIT)
                .map((clase, index) => (
                  <Grid key={index + clase.nombre} item xs={isActive(clase) ? 4 : 2}>
                    <MiniBox
                      linkto={`${path}/${clase.id}`}
                      heading={clase.nombre}
                      content={displayDateHours(clase.fecha)}
                      backgroundColor={isActive(clase) ? 'darkgreen' : 'gray'}
                    />
                  </Grid>
                ))}
            </Grid>
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <Grid container spacing={3}>
              {bonusClasses
                .sort((a, b) => (a.fecha < b.fecha ? -1 : 1))
                .slice(0, process.env.REACT_APP_MINIBOX_LIMIT)
                .map((clase, index) => (
                  <Grid key={index + clase.id} item xs={2}>
                    <MiniBox
                      linkto={`${path}/${clase.id}`}
                      heading={clase.nombre}
                      content={displayDateHours(clase.fecha)}
                      backgroundColor={isActive(clase) ? 'darkgreen' : 'gray'}
                    />
                  </Grid>
                ))}
            </Grid>
          </TabContainer>
        </SwipeableViews>
      </Paper>
    </div>
  )
}
export default NextClasses

import React from 'react'
import { hideModal } from 'redux/actions'
import { useDispatch } from 'react-redux'

import Close from '@material-ui/icons/Close'

const Modal = ({ children }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(hideModal())
  }

  return (
    <>
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          zIndex: 50,
          backgroundColor: 'rgba(173,173,173,0.50)',
        }}
      >
        <div
          style={{
            padding: 30,
            minWidth: '40%',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            zIndex: 100,
          }}
        >
          <Close onClick={handleClose} style={{ position: 'absolute', right: 50, top: 50 }} />
          {React.cloneElement(children, { handleClose: handleClose })}
        </div>
      </div>
    </>
  )
}

export default Modal

import { FETCH_ACCESOS, CLEAR_ACCESOS, FETCH_USUARIOS_BY_CLASE } from 'actionTypes'
import { enqueueSnackbar } from 'redux/actions'
import messages from 'messages'

const { REACT_APP_API_END } = process.env
const storeData = (type, data) => ({ type, data })

export const fetchAccesos = (userId, role, claseId) => (dispatch) => {
  const endpoint = `/claseGetAlumnosOnlineByCatedra?usuario_id=${userId}&usuario_role=${role}&clase_id=${claseId}`
  fetch(REACT_APP_API_END + endpoint, {
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch(storeData(FETCH_ACCESOS, res))
    })
    .catch((err) =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.getAccesos,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

export const fetchUsuariosByClase = (claseId) => (dispatch) => {
  dispatch(storeData(CLEAR_ACCESOS))

  const endpoint = `/claseGetUsuariosByClase?clase_id=${claseId}`
  fetch(REACT_APP_API_END + endpoint, {
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch(storeData(FETCH_USUARIOS_BY_CLASE, res))
    })
    .catch((err) =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.getAccesos,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

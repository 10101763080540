const { REACT_APP_COLOR_PRIMARY, REACT_APP_COLOR_SECONDARY } = process.env

const themeStyle = {
  palette: {
    primary: {
      light: REACT_APP_COLOR_PRIMARY,
      main: REACT_APP_COLOR_PRIMARY,
      dark: REACT_APP_COLOR_PRIMARY,
      contrastText: 'white',
    },
    secondary: {
      light: REACT_APP_COLOR_SECONDARY,
      main: REACT_APP_COLOR_SECONDARY,
      dark: REACT_APP_COLOR_SECONDARY,
      contrastText: 'white',
    },
    auxiliar: {
      red: {
        regular: '#CC0000',
        light: '#D32F2F',
        dark: '#A70000',
        accent: '#F17373',
      },
      redviolet: {
        regular: '#C2185B',
        light: '#E91E63',
        dark: '#AD1457',
        accent: '#F8BBD0',
      },
      violet: {
        regular: '#7B1FA2',
        light: '#9C27B0',
        dark: '#6A1B9A',
        accent: '#CE93D8',
      },
      blueviolet: {
        regular: '#512DA8',
        light: '#673AB7',
        dark: '#311B92',
        accent: '#9575CD',
      },
      darkblue: {
        regular: '#283593',
        light: '#3949AB',
        dark: '#1A237E',
        accent: '#7986CB',
      },
      blue: {
        regular: '#1565C0',
        light: '#1E88E5',
        dark: '#0D47A1',
        accent: '#64B5F6',
      },
      lightblue: {
        regular: '#03A9F4',
        light: '#4FC3F7',
        dark: '#0288D1',
        accent: '#81D4FA',
      },
      aquamarine: {
        regular: '#4DD0E1',
        light: '#80DEEA',
        dark: '#26C6DA',
        accent: '#B2EBF2',
      },
      bluegreen: {
        regular: '#00BFA5',
        light: '#80CBC4',
        dark: '#26A69A',
        accent: '#B2DFDB',
      },
      darkgreen: {
        regular: '#66BB6A',
        light: '#81C784',
        dark: '#4CAF50',
        accent: '#A5D6A7',
      },
      green: {
        regular: '#8BC34A',
        light: '#9CCC65',
        dark: '#7CB342',
        accent: '#C5E1A5',
      },
      lime: {
        regular: '#CDDC39',
        light: '#DCE775',
        dark: '#C0CA33',
        accent: '#E6EE9C',
      },
      yellow: {
        regular: '#FBC02D',
        light: '#FDD835',
        dark: '#F9A825',
        accent: '#FFF59D',
      },
      orange: {
        regular: '#EF6C00',
        light: '#F57C00',
        dark: '#E65100',
        accent: '#FFB74D',
      },
      darkorange: {
        regular: '#D84315',
        light: '#E64A19',
        dark: '#BF360C',
        accent: '#FF8A65',
      },
      gray: {
        regular: '#ccc',
      },
    },
    error: {
      light: '#CC0000',
      main: '#CC0000',
      dark: '#CC0000',
      contrastText: '#CC0000',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F0F0F0',
    },
    typography: {
      100: '#333333',
      90: '#666666',
      80: '#999999',
      60: '#CCCCCC',
      40: '#D8D8D8',
      20: '#F0F0F0',
      5: '#FAFAFA',
      0: '#FFFFFF',
    },
  },
  text: {
    primary: '#333',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  shadows: [
    '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
    '0px 1px 4px 2px rgba(173,173,173,0.50)',
    '0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)',
    '0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],

  typography: {
    fontFamily: ['Roboto Condensed', 'Fjalla One', 'Roboto'].join(','),
  },
  overrides: {
    typography: {
      // Name of the component ⚛️ / style sheet
      fontFamily: ['Roboto Condensed', 'Fjalla One', 'Roboto'].join(','),
    },
  },
}
export default themeStyle

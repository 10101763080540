import React, { useEffect, useState } from 'react'

import SectionCard from 'components/SectionCard'
import { Box } from '@material-ui/core'

const InfoAdicional = props => {
  const {
    entornosById,
    entornos,
    clase: { variables_generales },
  } = props

  const [generalVars, setGeneralVars] = useState('')

  useEffect(() => {
    setGeneralVars(entornosById ? entornos.length && entornos.find(entorno => entorno.variables_generales).variables_generales : variables_generales)
    // eslint-disable-next-line
  }, [])

  return (
    <SectionCard cardTitle={`Información Adicional`}>
      {!generalVars ? (
        <Box fontFamily="Roboto Condensed">No hay variables disponibles</Box>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: generalVars }} />
      )}
    </SectionCard>
  )
}

export default InfoAdicional

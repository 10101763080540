import messages from 'messages'
import { enqueueSnackbar } from 'redux/actions'
import { getToken } from '../helpers/reduxHelpers'
import { RECEIVE_MY_CONSOLE_ACCESS, ALL_CONSOLE_ACCESSES } from 'actionTypes'
import { fetchClaseDetail } from './clases'

const { REACT_APP_API_END } = process.env

const storeData = (type, data) => ({ type, data })

export const fetchMyConsoleAccess = ({ userId, catedraId, claseId, role }) => (dispatch) => {
  fetch(
    `${REACT_APP_API_END}/consoleAccessGetMine?usuario_id=${userId}&catedra_id=${catedraId}&clase_id=${claseId}&role=${role}`,
    {
      headers: new Headers({
        Authorization: getToken(),
      }),
    }
  )
    .then((response) => response.json())
    .then((response) => dispatch(storeData(RECEIVE_MY_CONSOLE_ACCESS, response)))
    .catch((error) => {
      console.error('[consolas.fetchConsoleAccess] Error: ', error)
      dispatch(
        enqueueSnackbar({
          message: messages.error.getMyConsoleAccess,
          options: {
            variant: 'error',
          },
        })
      )
    })
}

export const fetchAllConsoleAccess = ({ userId, catedraId, claseId, role }) => (dispatch) => {
  fetch(`${REACT_APP_API_END}/getAllAccessesByCatedra?usuario_id=${userId}&catedra_id=${catedraId}&clase_id=${claseId}&role=${role}`, {
    headers: new Headers({
      Authorization: getToken(),
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      const { error } = response

      if (error) throw new Error(error)

      dispatch(storeData(ALL_CONSOLE_ACCESSES, response))
    })
    .catch((error) => {
      console.error('[consolas.fetchConsoleAccess] Error: ', error)
      dispatch(
        enqueueSnackbar({
          message: messages.error.getMyConsoleAccess,
          options: {
            variant: 'error',
          },
        })
      )
    })
}

export const initMyConsoleAccess = ({ userId, catedraId, claseId, role }) => (dispatch) =>
  fetch(REACT_APP_API_END + `/initMyConsoleAccess`, {
    method: 'POST',
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ usuario_id: userId, clase_id: claseId, usuario_role: role, catedra_id: catedraId }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log('[consolas.initMyConsoleAccess]: response', response)
      dispatch(storeData(ALL_CONSOLE_ACCESSES, response))
      dispatch(fetchClaseDetail(userId, catedraId, claseId, role))
      dispatch(
        enqueueSnackbar({
          message: messages.success.initMyConsoleAccess,
          options: {
            variant: 'success',
          },
        })
      )
    })
    .catch((error) => {
      console.error('[consolas.initMyConsoleAccess] Error: ', error)
      dispatch(
        enqueueSnackbar({
          message: messages.error.initMyConsoleAccess,
          options: {
            variant: 'error',
          },
        })
      )
    })

export const deleteConsoleInfo = (dispatch) => {
  dispatch(storeData(ALL_CONSOLE_ACCESSES))
  dispatch(storeData(RECEIVE_MY_CONSOLE_ACCESS, {}))
}

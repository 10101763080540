import React from 'react'
import { SnackbarProvider } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'

//Incons
import Close from '@material-ui/icons/Close'
import Done from '@material-ui/icons/Done'
import InfoOutlined from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 60,
  },
  icon: {
    fontSize: 20,
  },
  info: { backgroundColor: 'grey' },
}))

const iconStyles = {
  paddingRight: 5,
}

const variantIcon = {
  success: <Done style={iconStyles} />,
  warning: <InfoOutlined style={iconStyles} />,
  error: <Close style={iconStyles} />,
  info: <InfoOutlined style={iconStyles} />,
}

export default props => {
  const classes = useStyles()

  return (
    <>
      <SnackbarProvider
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info,
        }}
        iconVariant={variantIcon}
        className={classes.root}
        maxSnack={4}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {props.children}
      </SnackbarProvider>
    </>
  )
}

import messages from 'messages'
import { enqueueSnackbar } from 'redux/actions'
import { ALL_CONSOLE_ACCESSES } from 'actionTypes'
import { fetchClaseDetail } from './clases'

const { REACT_APP_API_END } = process.env

const storeData = (type, data) => ({ type, data })

export const initMixed = ({ userId, catedraId, claseId, role }) => (dispatch) =>
  fetch(REACT_APP_API_END + `/mixedIniciar`, {
    method: 'POST',
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ usuario_id: userId, clase_id: claseId, usuario_role: role, catedra_id: catedraId }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log('λ: response', response)
      dispatch(storeData(ALL_CONSOLE_ACCESSES, response))
      dispatch(fetchClaseDetail(userId, catedraId, claseId, role))
      dispatch(
        enqueueSnackbar({
          message: messages.success.mixedInit,
          options: {
            variant: 'success',
          },
        })
      )
    })
    .catch((error) => {
      console.error('[consolas.mixedInit] Error: ', error)
      dispatch(
        enqueueSnackbar({
          message: messages.error.mixedInit,
          options: {
            variant: 'error',
          },
        })
      )
    })

import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Grid, Paper, Box } from '@material-ui/core'
import { fetchCatedras } from 'redux/actions'
import moment from 'moment'
import SectionCard from 'components/SectionCard'
import MiniBox from 'components/MiniBox'
import Tip from 'components/Tip'

// intl
import { FormattedMessage, useIntl } from 'react-intl'

const ListMaterias = ({ title, items, color }) => {
  return (
    <SectionCard cardTitle={title}>
      <Grid container spacing={3}>
        {items.map((clase, index) => (
          <Grid key={index + clase.name} item xs={2}>
            <MiniBox
              linkto={`/catedra/${clase.id}`}
              heading={clase.titulo.toUpperCase()}
              content={clase.subtitulo}
              footer={clase.carrera}
              backgroundColor={color}
            />
          </Grid>
        ))}
      </Grid>
    </SectionCard>
  )
}

const Dashboard = props => {
  const { user, role_active } = useSelector(({ me }) => me, shallowEqual)
  const catedras = useSelector(({ catedras }) => Object.values(catedras.byId), shallowEqual)
  const intl = useIntl()
  const alumno = role_active === 'alumno'
  let completed = []
  let incompleted = []

  let isLoading = catedras.length === 0
  if (catedras.length > 0) {
    catedras.map(catedra => {
      if (moment().isBefore(catedra.fecha_fin)) completed.push(catedra)
      else incompleted.push(catedra)
      return catedra
    })
    isLoading = false
  }

  return (
    <>
      <Paper>
        <Box fontSize="h4.fontSize" p={2} mt={1} mb={4} fontFamily="Fjalla One">
          <FormattedMessage id="dashboard.welcomeHeading" values={{ role: role_active === 'alumno' ? 'Estudiante' : 'Profesor' }} />
        </Box>
      </Paper>
      <Tip />
      {!isLoading && completed.length > 0 && (
        <ListMaterias title={intl.formatMessage({ id: 'dashboard.inProgressSubjects' })} items={completed} color={'darkgreen'} />
      )}
      {!isLoading && incompleted.length > 0 && (
        <ListMaterias title={intl.formatMessage({ id: `dashboard.pastSubjects.${alumno ? 'alumno' : 'profesor'}` })} items={incompleted} />
      )}
    </>
  )
}

export default Dashboard

import { RECEIVE_AUTH, RECEIVE_AUTH_DATA, RECEIVE_AUTH_TOKEN, RECEIVE_USER_ROLE, LOGOUT } from 'actionTypes'
import moment from 'moment'
import { PROFESOR } from '../../helpers/constants'

const INIT_STATE = {
  auth: false,
  user: {},
}

export default (state = INIT_STATE, { type, data }) => {
  switch (type) {
    case RECEIVE_AUTH:
      return Object.assign({}, state, { auth: data })
    case RECEIVE_AUTH_TOKEN:
      sessionStorage.setItem('token', data)
      sessionStorage.setItem(
        'tokenExp',
        moment()
          .add(process.env.REACT_APP_AUTH_TTL, 'ms')
          .toISOString()
      )
      return Object.assign({}, state, { token: data })
    case LOGOUT:
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('tokenExp')
      return INIT_STATE
    case RECEIVE_AUTH_DATA:
      let [role_active] = data.roles

      const { roles = [] } = data

      if (roles.length > 1) role_active = roles.includes(PROFESOR) ? PROFESOR : role_active
        return Object.assign({}, state, { user: data, role_active })

     case RECEIVE_USER_ROLE:
      return { ...state, role_active: data }
    default:
      return state
  }
}

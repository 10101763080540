import React, { useState, useEffect, useRef } from 'react'
import { usePdf } from 'react-pdf-js'
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ZoomIn from '@material-ui/icons/ZoomIn'
import ZoomOut from '@material-ui/icons/ZoomOut'
import { IconButton, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import Loading from './Loading'

const useStyles = makeStyles((theme, props) => ({
  input: {
    display: 'none',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tools: {
    position: 'absolute',
    left: '4%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffffd9',
    marginTop: '2%',
    padding: '1% 0',
    borderTop: '0.2px solid #80808045',
  },
  pdfContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    overflow: 'hidden',
    padding: '2%',
  },
  pdfCanvas: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.shadows[1],
  },
  contentView: {
    position: 'relative',
    display: 'flex',
  },
  paginationIcon: {
    fontSize: 43,
  },
  zoomIcon: {
    fontSize: 30,
  },
}))

const PdfDisplay = props => {
  const { guia } = props
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(null)
  const [zoom, setZoom] = useState(1.4)

  const classes = useStyles()

  const renderPagination = (page, pages) => {
    if (!pages) {
      return null
    }

    return (
      <div className={classes.pagination}>
        <IconButton
          onClick={() => (page !== 1 ? setPage(page - 1) : null)}
          color="primary"
          className={classes.button}
          aria-label="add to shopping cart"
        >
          <ChevronLeft className={classes.paginationIcon} />
        </IconButton>
        <Typography variant="subtitle1" component="p">
          {page}
        </Typography>
        <IconButton
          onClick={() => (page !== pages ? setPage(page + 1) : null)}
          color="primary"
          className={classes.button}
          aria-label="add to shopping cart"
        >
          <ChevronRight className={classes.paginationIcon} />
        </IconButton>
      </div>
    )
  }

  const canvasEl = useRef(null)

  const [loading, numPages] = usePdf({
    file: guia,
    page,
    scale: zoom,
    canvasEl,
  })

  useEffect(() => {
    setPages(numPages)
  }, [numPages])

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loading />
        </div>
      ) : (
        <>
          {renderPagination(page, pages)}
          <div className={classes.contentView}>
            <div className={classes.tools}>
              <IconButton
                onClick={() => setZoom(zoom < 2.25 ? zoom + 0.25 : zoom)}
                color="primary"
                className={classes.button}
                aria-label="add to shopping cart"
              >
                <ZoomIn className={classes.zoomIcon} />
              </IconButton>
              <IconButton
                onClick={() => setZoom(zoom > 0.75 ? zoom - 0.25 : zoom)}
                color="primary"
                className={classes.button}
                aria-label="add to shopping cart"
              >
                <ZoomOut className={classes.zoomIcon} />
              </IconButton>
              <IconButton onClick={() => window.open(guia, '_blank')} color="primary" className={classes.button} aria-label="add to shopping cart">
                <VisibilityOutlined />
              </IconButton>
            </div>
            <div className={classes.pdfContainer}>
              <canvas className={classes.pdfCanvas} ref={canvasEl} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default PdfDisplay

import React, { useState } from 'react'

import { Popover, TextField } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar'
import { makeStyles } from '@material-ui/core/styles'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  dateInput: {
    border: `0.5px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(1),
    borderRadius: '5px',
  },
}))

const MultiDatePicker = (props) => {
  const { allowRange, datePickerActions, ...rest } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dateRange, setDateRange] = useState({})
  console.log('λ: MultiDatePicker -> dateRange', dateRange)

  const theme = useTheme()
  const classes = useStyles()

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const CalendarWithRange = withRange(Calendar)

  return (
    <div>
      <TextField onFocus={handleOpen} id="date" value={moment(props.selected).format('DD/MM/YY')} margin="normal" />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <InfiniteCalendar
          {...rest}
          selected={dateRange}
          Component={allowRange && CalendarWithRange}
          width={300}
          rowHeight={40}
          height={300}
          theme={{
            selectionColor: theme.palette.secondary.main,
            textColor: {
              default: '#333',
              active: '#FFF',
            },
            weekdayColor: theme.palette.primary.main,
            headerColor: theme.palette.secondary.main,
            floatingNav: {
              background: 'rgba(81, 67, 138, 0.96)',
              color: '#FFF',
              chevron: '#FFA726',
            },
          }}
        />
      </Popover>
    </div>
  )
}

export default MultiDatePicker

import React from 'react'

import LinearProgress from 'components/LinearProgress'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  point: {
    height: 10,
    width: 10,
    backgroundColor: (props) => {
      const colorPath = props.isIn ? 'blue' : 'red'
      return theme.palette.auxiliar[colorPath].regular
    },
    borderRadius: '50%',
    marginRight: 10,
  },
  textValue: {},
}))
const InClass = (props) => {
  const classes = useStyles({ isIn: !!props.isIn })
  let textValue = props.isIn ? 'Dentro' : 'Fuera'
  return (
    <div className={classes.container}>
      <div className={classes.point}></div>
      <Typography className={classes.textValue}>{textValue}</Typography>
    </div>
  )
}

export default [
  {
    title: 'Alumno',
    field: 'alumno',
  },
  { title: 'Asignatura', field: 'asignatura' },
  { title: 'Seccion', field: 'seccion' },
  { title: 'Clases tomadas', field: 'clases_tomadas' },
  {
    title: 'Clases Ausentes',
    field: 'clases_ausentes',
  },

  {
    title: 'Clases Proyectadas',
    field: 'clases_proyectadas',
  },
  {
    title: '% de asistencia',
    render: (rowData) => {
      const value = Math.round((rowData.clases_tomadas * 100) / rowData.clases_proyectadas)
      return <LinearProgress colorState="blue" value={value} />
    },
  },
]

import { RECEIVE_REPORT, SET_REPORT_FILTER } from 'actionTypes'
import { enqueueSnackbar } from 'redux/actions'
import messages from 'messages'

const { REACT_APP_API_END } = process.env
const storeData = (type, data) => ({ type, data })

export const fetchReports = (reportEndpoint, params) => (dispatch) => {
  const formattedParams = params.length ? params.map((item) => `${item.name}=${JSON.stringify(item.payload)}`).join('&') : ''

  return fetch(REACT_APP_API_END + `/${reportEndpoint}?${formattedParams}`, {
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch(storeData(RECEIVE_REPORT, { endpoint: reportEndpoint, report: res }))
    })
    .catch((err) =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.getReport,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

export const setReportFilter = (filter) => ({
  type: SET_REPORT_FILTER,
  data: filter,
})

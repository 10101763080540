export const SECURE_URL_PREFIX = 'https://';
export const AMAZON_CONSOLE_LINK = `${SECURE_URL_PREFIX}aws.amazon.com/console/`;

/* Unidades */
export const ACCESO_A_CONSOLA = 'acceso a consola';
export const TRADITIONAL = 'tradicional';
export const MIXED = 'mixta';

/* Roles */
export const ALUMNO = 'alumno';
export const PROFESOR = 'profesor';
export const ADMIN = 'admin';

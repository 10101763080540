import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { removeSelectedSnapshot, selectSnapshot } from 'redux/actions'

import { Switch, MenuItem, FormControl, Select } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Check, Close } from '@material-ui/icons'
import Loading from 'components/Loading'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}))

const EncenderSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.background.default,
    '&$checked': {
      color: theme.palette.auxiliar.blue.regular,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.auxiliar.blue.light,
      opacity: 1,
    },
  },
  checked: {},
  track: {},
}))(Switch)

const Rollback = ({ rowData, inProgress }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const entornos = useSelector(({ entornos }) => entornos, shallowEqual)

  const selectedSnapId = entornos.byId[rowData.id].selectedSnapshot
    ? entornos.byId[rowData.id].selectedSnapshot.snapshot_id
    : undefined
  const selectedSnap = entornos.byId[rowData.id].snapshots.find((snap) => snap.id === selectedSnapId)

  const handleSelect = (event) => {
    const snapshot = event.target.value
    const entornoId = rowData.id

    dispatch(
      snapshot.nombre !== undefined ? selectSnapshot(entornoId, snapshot.id) : removeSelectedSnapshot(entornoId, snapshot.id)
    )
  }

  const disabled = !inProgress || !rowData.activo || !rowData.snapshots.length || rowData.estado === 'rolling_back'

  return (
    <FormControl className={classes.formControl}>
      <Select disabled={disabled} value={selectedSnap || 'No aplica'} onChange={(event) => handleSelect(event)}>
        <MenuItem value={'No aplica'}>{'No aplica'}</MenuItem>
        {rowData.snapshots.map((item, i) => (
          <MenuItem key={item + i} value={item}>
            {item.nombre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const estadoMap = {
  stopped: <Close style={{ color: '#c00' }} />,
  running: <Check style={{ color: 'green' }} />,
  loading: <Loading size={24} />,
}

export const EntornosCols = (theme, handlers, noDisplayCols = [], clase) => {
  //no Display Cols is array [...col.title ]

  const inProgress = clase.estado === 'inProgress'

  const AllCols = [
    {
      title: 'ip',
      field: 'ip',
      type: 'string',
      render: (rowData) => rowData.estado === 'running' && rowData.ip,
      cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
    },
    {
      title: 'usuario',
      field: 'usuario',
      type: 'string',
      cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
    },
    {
      title: 'contraseña',
      field: 'contrasena',
      type: 'string',
      cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
    },
    {
      title: 'hostname',
      field: 'hostname',
      type: 'string',
      cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
    },
    {
      title: 'os',
      field: 'os',
      type: 'string',
      cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
    },
    {
      title: '¿Encender?',
      field: 'activo',
      render: (rowData) => (
        <div>
          Off
          <EncenderSwitch disabled={inProgress} onChange={() => handlers.active(rowData.id)} checked={rowData.activo} />
          On
        </div>
      ),
      cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
    },
    {
      title: 'estado',
      field: 'estado',
      type: 'string',
      render: (rowData) => estadoMap[rowData.estado] || estadoMap['loading'],
    },
    {
      title: 'Rollback',
      field: 'snapshots',
      type: 'string',
      cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
      render: (rowData) => <Rollback inProgress={inProgress} rowData={rowData} />,
    },
  ]

  const filterCols = AllCols.filter((col) => !noDisplayCols.includes(col.title))

  return filterCols
}

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

import Close from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  bgprimary: {
    backgroundColor: theme.palette.auxiliar.blue.light,
    color: 'white',
  },
  fontWidth: {
    fontWeight: '600',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeBtn: {
    fontSize: 18,
  },
}))

const Tip = props => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  return !open ? null : (
    <Box br={3} className={classes.bgprimary} p={2} pb={3} mb={4}>
      <div className={classes.header}>
        <Typography className={classes.fontWidth} variant="h6" component="h3" fontFamily="Fjalla One">
          ¡Tip!
        </Typography>
        <Close onClick={handleClose} className={classes.closeBtn} />
      </div>

      <Typography component="p" fontFamily="Roboto Condensed">
        Esta es la nueva plataforma de INACAP para poder acceder a las clases y entornos tanto de los Estudiantes como de los Profesores para cada
        clase.
      </Typography>
      <Typography component="small" fontFamily="Roboto Condensed">
        Aquí tienes un{' '}
        <a href="https://inacap-guia-prod.s3.amazonaws.com/Manual+de+Usuario+-+Entornos+Virtuales+AWS.pdf" target="_blank" style={{ color: '#fff' }}>
          manual de usuario
        </a>{' '}
        de acciones básicas. Si tienes dudas, puedes escribirnos a ayuda@inacap.cl ¡Que lo disfrutes!
      </Typography>
    </Box>
  )
}
export default Tip

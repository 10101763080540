import React, { useState, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import { Collapse, ListItemText, ListItemIcon, ListItem, List } from '@material-ui/core'
import Dashboard from '@material-ui/icons/Dashboard'
import Bookmark from '@material-ui/icons/Bookmark'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ShowChart from '@material-ui/icons/ShowChart'
import WithAuthorization from './WithAuthorization'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '230px',
    height: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    fontFamily: 'Fjalla One',
    paddingTop: 0,
  },
  listI: {
    padding: '12px 5px 12px 15px',
  },
  nested: {
    padding: '12px 5px 12px 15px',
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.typography[60],
  },
  listIcon: {
    paddingRight: 10,
    minWidth: 18,
    color: 'black',
  },
  IconSize: {
    fontSize: 18,
  },
  navLink: {
    textDecoration: 'none',
    color: theme.palette.common.black,
  },
  navActive: {
    borderLeft: `3px solid ${theme.palette.secondary.light}`,
  },
  navLinkActive: {
    borderLeft: `3px solid ${theme.palette.secondary.light}`,
    display: 'block',
  },
  selected: {},
  fontSizeItem: {
    fontSize: 12,
  },
}))

const ItemIcon = ({ icon }) => {
  const classes = useStyles()
  return <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
}

const ItemLink = ({ link, title, icon, style, size }) => {
  const classes = useStyles()
  return (
    <NavLink exact activeClassName={classes.navLinkActive} className={classes.navLink} to={link}>
      <ListItem style={style} button>
        {icon && <ItemIcon icon={icon} />}
        <ListItemText classes={size && { primary: classes.fontSizeItem }} primary={title} />
      </ListItem>
    </NavLink>
  )
}

const ItemCollap = ({ title, id, childs, icon, style, size, firstid, parentid, roleActive }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Fragment>
      <ListItem style={style} button onClick={() => setOpen(!open)}>
        {icon && <ItemIcon icon={icon || <Bookmark className={classes.IconSize} />} />}
        <ListItemText classes={size && { primary: classes.fontSizeItem }} primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {childs.map((item, index) => {
            if (item.hasOwnProperty('childs'))
              return (
                <ItemCollap
                  size={true}
                  style={{ backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingLeft: 45 }}
                  key={index + item.title}
                  {...item}
                  firstid={parentid}
                  parentid={item.id}
                />
              )
            else
              return (
                <ItemLink
                  size={true}
                  style={{ backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingLeft: 60 }}
                  key={item.title}
                  link={item.path ? item.path : `/catedra/${item.id}`}
                  title={intl.formatMessage({ id: item.title })}
                />
              )
          })}
        </List>
      </Collapse>
    </Fragment>
  )
}

const Sidebar = () => {
  const { tree } = useSelector(({ sidebar }) => sidebar, shallowEqual)
  let { role_active } = useSelector(({ me }) => me, shallowEqual)
  const intl = useIntl()
  const classes = useStyles()

  return (
    <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
      <ItemLink
        title={intl.formatMessage({ id: 'sidebar.controlPanel' })}
        link={
          role_active === 'admin'
            ? `/admin/clases?fecha=${moment()
                .utc()
                .format('YYYY-MM-DD')}`
            : '/'
        }
        icon={<Dashboard className={classes.IconSize} />}
      />
      <WithAuthorization roles={['admin']}>
        <ItemCollap
          childs={[
            { title: 'sidebar.ClassAttendance', path: '/admin/reports/ClassAttendance', id: 1 },
            { title: 'sidebar.ClassExternalAttendance', path: '/admin/reports/ClassExternalAttendance', id: 2 },
            { title: 'sidebar.StudentAttendance', path: '/admin/reports/StudentAttendance', id: 3 },
            { title: 'sidebar.StudentBonusAttendance', path: '/admin/reports/StudentBonusAttendance', id: 4 },
          ]}
          title={intl.formatMessage({ id: 'sidebar.reports' })}
          link={'/admin/clases'}
          icon={<ShowChart className={classes.IconSize} />}
        />
      </WithAuthorization>

      {tree && tree.map((item, index) => <ItemCollap key={index + item.title} {...item} parentid={item.id} icon={true} />)}
    </List>
  )
}

export default Sidebar

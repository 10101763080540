import React, { useEffect } from 'react'
import { Grid, Fab, Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/core/styles'
import { debounce } from 'lodash'

import SectionCard from './SectionCard'
import { useInterval } from 'customHooks/useInterval'

/* Cols */
import { EntornosCols } from './cols/Entornos'

/* Redux */
import { connect, useDispatch } from 'react-redux'
import { fetchMisEntornos, fetchAlumnoEntornos, toggleActiveEntorno, showModal, resetearAccesos } from 'redux/actions'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: '#d8d8d8',
    color: '#333',
  },
  button2: {
    backgroundColor: '#666',
    color: '#fff',
  },
}))

const Entornos = props => {
  const {
    clase,
    noDisplayCols,
    heading,
    catedraId,
    claseId,
    misEntornos,
    alumnoEntornos,
    entornosById,
    isInProgressClass,
    activeEntornos,
    me: { role_active, user },
  } = props
  const theme = useTheme()
  const classes = useStyles()

  const dispatch = useDispatch()

  let entornosType = entornosById ? 'alumnoEntornos' : 'misEntornos'

  const handlers = {
    active: EntornoId => dispatch(toggleActiveEntorno(EntornoId)),
  }

  const entornosMap = {
    alumnoEntornos: {
      fetch: fetchAlumnoEntornos(user.id, catedraId, claseId, role_active, entornosById),
      columns: EntornosCols(theme, handlers, noDisplayCols, clase),
      data: alumnoEntornos,
    },
    misEntornos: {
      fetch: fetchMisEntornos(user.id, catedraId, claseId),
      columns: EntornosCols(theme, handlers, noDisplayCols, clase),
      data: misEntornos,
    },
  }

  useEffect(() => {
    dispatch(entornosMap[entornosType].fetch)
    // eslint-disable-next-line
  }, [])

  const cronEntornos = () => {
    dispatch(entornosMap[entornosType].fetch)
  }

  useInterval(cronEntornos, isInProgressClass || activeEntornos.length ? 60000 : null)

  const handlerResetAccesos = debounce(() => {
    const baseEntornosMap = {
      misEntornos,
      alumnoEntornos,
    }

    const rollbackArray = baseEntornosMap[entornosType].reduce((accum, current) => {
      current.selectedSnapshot && accum.push(current.selectedSnapshot)
      return accum
    }, [])

    rollbackArray.length && dispatch(resetearAccesos(entornosById ? entornosById : user.id, rollbackArray))
  }, 1000)

  return (
    <MaterialTable
      isLoading={!entornosMap[entornosType].data.length}
      Toolbar={null}
      columns={entornosMap[entornosType].columns}
      data={entornosMap[entornosType].data}
      options={{
        loadingType: 'linear',
        toolbar: false,
        search: false,
        actionsColumnIndex: -1,
        rowStyle: rowData => ({
          backgroundColor: rowData.tableData.id % 2 === 0 ? theme.palette.grey[100] : theme.palette.background.paper,
        }),
        headerStyle: {
          padding: '.5rem',
          fontSize: '.9rem',
          fontFamily: theme.typography.fontFamily,
          fontWeight: '700',
          textTransform: 'uppercase',
          letterSpacing: 0,
          color: theme.text.primary,
        },
      }}
      components={{
        Container: props => {
          const resetAccesBtn =
            role_active === 'profesor' ? (
              <Fab onClick={handlerResetAccesos} variant="extended" size="medium" aria-label="Add" className={classes.button2}>
                Resetear Accesos
              </Fab>
            ) : null
          const InfAddicional = (
            <Fab
              onClick={() => dispatch(showModal('INFO_ADICIONAL', { clase, entornosById, entornos: entornosMap['alumnoEntornos'].data }))}
              variant="extended"
              size="medium"
              aria-label="Add"
              className={classes.button}
            >
              Información Adicional
            </Fab>
          )

          return (
            //Whenever you use table inside a SectionCard use 'noContentPadding' attr
            <SectionCard noContentPadding cardTitle={heading}>
              {props.children}
              <Box mt={2} />
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>{InfAddicional}</Grid>
                <Grid item>{resetAccesBtn}</Grid>
              </Grid>
            </SectionCard>
          )
        },
      }}
    />
  )
}

const selectMisEntornos = (state, claseId) => {
  return state.clases.byId[claseId].misEntornos ? state.clases.byId[claseId].misEntornos.map(entornoId => state.entornos.byId[entornoId]) : []
}

const selectAlumnoEntornos = (state, claseId) => {
  return state.clases.byId[claseId].alumnoEntornos ? state.clases.byId[claseId].alumnoEntornos.map(entornoId => state.entornos.byId[entornoId]) : []
}

const mapStateToProps = (state, ownProps) => {
  const { claseId } = ownProps

  return {
    alumnoEntornos: selectAlumnoEntornos(state, claseId),
    misEntornos: selectMisEntornos(state, claseId),
    activeEntornos: selectMisEntornos(state, claseId).filter(entorno => entorno.estado !== 'stopped'),
  }
}

export default connect(
  mapStateToProps,
  null
)(Entornos)

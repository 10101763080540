// User
export const RECEIVE_AUTH = 'RECEIVE_AUTH'
export const RECEIVE_AUTH_DATA = 'RECEIVE_AUTH_DATA'
export const RECEIVE_AUTH_TOKEN = 'RECEIVE_AUTH_TOKEN'
export const RECEIVE_USER_ROLE = 'RECEIVE_USER_ROLE'
export const LOGOUT = 'LOGOUT'
// Sidebar
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const RECEIVE_SIDEBAR_DATA = 'RECEIVE_SIDEBAR_DATA'
// Clases
export const FETCH_CLASES = 'FETCH_CLASES'
export const FETCH_CLASE_DETAIL = 'FETCH_CLASE_DETAIL'
export const UPDATE_CLASE = 'UPDATE_CLASE'
export const RESET_CLASES = 'RESET_CLASES'
// Entornos
export const FETCH_ENTORNO = 'FETCH_ENTORNO'
export const FETCH_ENTORNOS = 'FETCH_ENTORNOS'
export const TOGGLE_ACTIVE_ENTORNO = 'TOGGLE_ACTIVE_ENTORNO'
export const SELECT_SNAPSHOT = 'SELECT_SNAPSHOT'
export const REMOVE_SELECTED_SNAPSHOT = 'REMOVE_SELECTED_SNAPSHOT'
// Console Accesses
export const RECEIVE_MY_CONSOLE_ACCESS = 'RECEIVE_MY_CONSOLE_ACCESS'
export const ALL_CONSOLE_ACCESSES = 'ALL_CONSOLE_ACCESSES'
// Catedras
export const FETCH_CATEDRAS = 'FETCH_CATEDRAS'
export const UPDATE_CATEDRA = 'UPDATE_CATEDRA'
// Accesos
export const FETCH_ACCESOS = 'FETCH_ACCESOS'
export const CLEAR_ACCESOS = 'CLEAR_ACCESOS'
export const FETCH_USUARIOS_BY_CLASE = 'FETCH_USUARIOS_BY_CLASE'
// Modal
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
// Nofifications
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'
// Settings
export const CHANGE_LOCALE = 'CHANGE_LOCALE'
// Reports
export const RECEIVE_REPORT = 'RECEIVE_REPORT'
export const SET_REPORT_FILTER = 'SET_REPORT_FILTER'

import React from 'react'
import Entornos from 'components/Entornos'

export default props => {
  return (
    <Entornos
      noDisplayCols={props.noDisplayCols || ['¿Encender?']}
      heading={`Accesos de ${props.acceso.nombre} ${props.acceso.apellido_paterno}`}
      {...props}
    />
  )
}

import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { changeRole } from 'redux/actions'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Box from '@material-ui/core/Box'
import { AccountCircle, Person } from '@material-ui/icons'
import { PROFESOR, ADMIN, ALUMNO } from '../helpers/constants'
const { REACT_APP_ISOLOGO } = process.env

const useStyles = makeStyles(theme => ({
  AppbarRoot: {
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[0],
  },
  ToolbarRoot: {
    borderTop: `6px solid ${theme.palette.secondary.light}`,
    boxShadow: theme.shadows[1],
    minHeight: 66,
    zIndex: 2,
    padding: '0px 15px',
  },
  flexGrow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  logoAppbar: {
    width: 140,
    height: 40,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginRight: 10,
    marginLeft: -1,
  },
  name: {
    color: theme.text.primary,
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
  },
  iconPerson: {
    fontSize: 20,
  },
  headerTitle: {
    paddingLeft: 12,
    borderLeft: '2px solid #d8d8d8',
    color: theme.text.primary,
    margin: 0,
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

const PrimarySearchAppBar = props => {
  const { user, role_active } = useSelector(({ me }) => me, shallowEqual)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null)
  }

  const handleChangeRol = role => {
    props.location.pathname === '/'
      ? dispatch(changeRole(role))
      : setTimeout(() => {
          dispatch(changeRole(role))
        }, 0)
    props.history.push('/')
    handleMenuClose()
  }

  function handleMenuClose() {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const roleMap = {
    [PROFESOR]: 'Profesor',
    [ALUMNO]: 'Estudiante',
    [ADMIN]: 'Admin',
  }

  // function handleLogout() {
  //   // dispatch(logout())
  //   window.location.href = REACT_APP_AUTH_LOGOUT + window.location.origin
  // }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user.roles.map(role => (
        <MenuItem key={role} onClick={() => handleChangeRol(role)}>
          {roleMap[role]}
        </MenuItem>
      ))}
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton aria-label="Account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  )
  return (
    <>
      <AppBar position="static" className={classes.AppbarRoot}>
        <Toolbar className={classes.ToolbarRoot}>
          <div style={{ display: 'block', width: '30px', height: '6px', backgroundColor: '#fff', position: 'absolute', top: '-6px', left: '15px' }} />
          <div className={classes.logoAppbar} style={{ backgroundImage: `url(${REACT_APP_ISOLOGO})`, backgroundSize: 'contain'}} />

          <Box fontFamily="Fjalla One" className={classes.headerTitle} fontSize="h5.fontSize" m={1}>
            Entornos Virtuales
          </Box>

          <div className={classes.flexGrow} />
          <div className={classes.sectionDesktop}>
            <Box
              edge="end"
              aria-label="Account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={user.roles.length > 1 ? handleProfileMenuOpen : null}
              className={classes.name}
              fontFamily={'Roboto Condensed'}
            >
              <Person className={classes.iconPerson} />
              <Box ml={0.5} width={180}>
                {user.nombre + ' ' + user.apellido_paterno + ' ' + user.apellido_materno}
              </Box>
              <Box component="small" ml={1} mr={2}>
                {roleMap[role_active]}
              </Box>
            </Box>
            {/* <IconButton aria-label="Exit" aria-haspopup="true" style={{ color: 'text.primary' }} onClick={handleLogout}>
              <ExitToApp />
            </IconButton> */}
          </div>
          <div className={classes.sectionMobile}>
            {/* <IconButton aria-label="Show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleLogout} color="default">
              <ExitToApp />
            </IconButton> */}
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </>
  )
}

export default withRouter(PrimarySearchAppBar)

import React, { useEffect, useState } from 'react'
import { Stepper, StepLabel, Step, StepConnector } from '@material-ui/core'
import Grow from '@material-ui/core/Grow'
import { isEmpty } from 'lodash'
import Loading from './../components/Loading'

import SettingsIcon from '@material-ui/icons/Settings'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import VideoLabelIcon from '@material-ui/icons/VideoLabel'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { getAccessLink } from '../helpers/constantUtils'
import clsx from 'clsx'

const useIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

const StyledConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 1,
  },
}))(StepConnector)

function ColorlibStepIcon(props) {
  const classes = useIconStyles()
  const { active, completed } = props

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

const renderLine = (line, step, index) => {
  if (step === 0 && index === 1)
    return (
      <a href={line} key={index} target="_blank">
        {line}
      </a>
    )

  return <div key={index}>{line}</div>
}

//TODO: Reconstruir componente a algo mas simple sin stepper.
const ConsoleAccess = ({ access, isLoadingAccess }) => {
  const [activeStep, setActiveStep] = useState(0)
  const initialCreds = {
    consoleLink: '',
    username: '',
    password: '',
  }
  const [credentials, setCredentials] = useState(initialCreds)

  useEffect(() => {
    isEmpty(access)
      ? setCredentials(initialCreds)
      : setCredentials({
          consoleLink: getAccessLink(access.account_number),
          // TODO This is hardcoded due to problems with accesses in prod, fix someday
          username: 'alumno', // access.username,
          password: 'Inacap2022!', // access.password,
        })
  }, [access])

  const { consoleLink, username, password } = credentials
  const steps = [
    ['Ingresa a', consoleLink],
    ['Ingresa los siguientes datos:', `Usuario: ${'alumno'/* username */}`, `Contraseña: ${'Inacap2022!'/* password */}`],
    ['Empieza a Ejercitar'],
  ]

  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<StyledConnector />}>
      {steps.map((label, index) => (
        <Step key={label + index}>
          <Grow timeout={index * 1000} in={true}>
            <StepLabel StepIconComponent={isLoadingAccess ? Loading : ColorlibStepIcon}>
              {label.map((line, lineIndex) => renderLine(line, index, lineIndex))}
            </StepLabel>
          </Grow>
        </Step>
      ))}
    </Stepper>
  )
}

export default ConsoleAccess

import React from 'react'

/** Material UI  */
import { makeStyles } from '@material-ui/core/styles'
import { Fab, Box, DialogActions } from '@material-ui/core/'

import SectionCard from 'components/SectionCard'

const useStyles = makeStyles(theme => ({
  content: {
    padding: '10px 0',
  },
}))

const WarningInitClase = props => {
  const { handleClose, handlerStartClase, clase } = props
  const classes = useStyles()

  const handleComenzar = () => {
    handlerStartClase()
    handleClose()
  }
  return (
    <SectionCard>
      <Box fontFamily="Fjalla One" fontSize="h5.fontSize" mb={2}>
        Se está por iniciar la {clase.nombre}
      </Box>
      <Box className={classes.content} fontFamily="Roboto Condensed">
        Recuerda que no puedes volver a marcar los servidores que serán encendidos durante la clase.
      </Box>
      <DialogActions>
        <Fab onClick={handleClose} variant="extended" size="medium" aria-label="Add">
          Cancelar
        </Fab>
        <Fab onClick={handleComenzar} variant="extended" size="medium" aria-label="Add" color="primary">
          Comenzar
        </Fab>
      </DialogActions>
    </SectionCard>
  )
}

export default WarningInitClase

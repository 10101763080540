import React from 'react'
import { Check, Close } from '@material-ui/icons'
import { Visibility } from '@material-ui/icons'
import { getAccessLink } from 'helpers/constantUtils'

const estadoMap = {
  close: <Close style={{ color: '#c00' }} />,
  open: <Check style={{ color: 'green' }} />,
}

const defaultFiller = '-'

const usuarioAccesosCols = (theme, role_active, handlerShowModalEntornos) => {
  if (role_active === 'admin') {
    return [
      {
        title: 'nombre',
        field: 'nombre',
        type: 'string',
        cellStyle: { fontFamily: theme.typography.fontFamily },
        render: (rowData) => `${rowData.nombre} ${rowData.apellido_paterno} ${rowData.apellido_materno}`,
      },
      {
        title: 'Rol',
        field: 'rol',
        type: 'string',
        render: (rowData) => (rowData.rol === 'alumno' ? 'Estudiante' : 'Profesor'),
        cellStyle: { fontFamily: theme.typography.fontFamily },
      },
      {
        title: 'online',
        field: 'online',
        type: 'string',
        render: (rowData) => (rowData.estado ? estadoMap['open'] : estadoMap['close']),
      },
      {
        title: 'acceso',
        field: 'acceso',
        type: 'string',
        cellStyle: { fontFamily: theme.typography.fontFamily },
        render: (rowData) => {
          return (
            <Visibility
              onClick={() => handlerShowModalEntornos(rowData)}
              style={{ fontSize: 16, opacity: 0.5, cursor: 'pointer' }}
            />
          )
        },
      },
    ]
  }

  return [
    {
      title: 'nombre',
      field: 'nombre',
      type: 'string',
      cellStyle: { fontFamily: theme.typography.fontFamily },
      render: (rowData) => `${rowData.nombre} ${rowData.apellido_paterno} ${rowData.apellido_materno}`,
    },
    {
      title: 'access link',
      field: 'account_number',
      type: 'string',
      render: ({ account_number }) => (account_number ? getAccessLink(account_number) : defaultFiller),
    },
    {
      title: 'user',
      field: 'user',
      type: 'string',
      render: (rowData) => rowData.user || defaultFiller,
    },
    {
      title: 'password',
      field: 'password',
      type: 'string',
      render: (rowData) => rowData.password || defaultFiller,
    },
    {
      title: 'online',
      field: 'online',
      type: 'string',
      render: (rowData) => (rowData.online ? estadoMap['open'] : estadoMap['close']),
    },
    {
      title: 'acceso',
      field: 'acceso',
      type: 'string',
      cellStyle: { fontFamily: theme.typography.fontFamily },
      render: (rowData) => {
        return (
          <Visibility
            onClick={() => handlerShowModalEntornos(rowData)}
            style={{ fontSize: 16, opacity: 0.5, cursor: 'pointer' }}
          />
        )
      },
    },
  ]
}
export default usuarioAccesosCols

import { RECEIVE_AUTH, RECEIVE_AUTH_DATA, RECEIVE_AUTH_TOKEN, RECEIVE_USER_ROLE, LOGOUT } from 'actionTypes'
const { REACT_APP_API_END } = process.env

const storeData = (type, data) => ({ type, data })

export const fetchUser = (token) => (dispatch) => {
  fetch(REACT_APP_API_END + '/session', {
    headers: new Headers({
      Authorization: token,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch(storeData(RECEIVE_AUTH, true))
      dispatch(storeData(RECEIVE_AUTH_TOKEN, token))
      dispatch(storeData(RECEIVE_AUTH_DATA, res))
    })
    .catch((err) => console.error(err))
}

export const changeRole = (role) => (dispatch) => {
  dispatch(storeData(RECEIVE_USER_ROLE, role))
}

export const logout = () => (dispatch) => dispatch(storeData(LOGOUT))

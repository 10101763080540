import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Language, Mail, Phone } from '@material-ui/icons'
const { REACT_APP_FOOTER_MESA, REACT_APP_FOOTER_MAIL, REACT_APP_FOOTER_PHONE, 
  REACT_APP_FOOTER_SUPPORT, REACT_APP_FOOTER_SUPPORT_HREF, REACT_APP_FOOTER_MAIL_HREF,
  REACT_APP_FOOTER_PHONE_HREF} = process.env

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(2),
    marginTop: 'auto',
    backgroundColor: '#888',
    color: 'white',
    fontFamily: 'Roboto Condensed',
    fontSize: '16px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    textDecoration: 'none',
  },
  wpfooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // maxWidth: '75%'
  },
}))
const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <div className={classes.wpfooter}>
        <span>{REACT_APP_FOOTER_MESA}:</span>
        <a href={REACT_APP_FOOTER_SUPPORT_HREF} className={classes.flexCenter}>
          <Language style={{ marginRight: '4px' }} />
          <span>{REACT_APP_FOOTER_SUPPORT}</span>
        </a>
        <a href={REACT_APP_FOOTER_MAIL_HREF} className={classes.flexCenter}>
          <Mail style={{ marginRight: '4px' }} />
          <span>{REACT_APP_FOOTER_MAIL}</span>
        </a>
        <a href={REACT_APP_FOOTER_PHONE_HREF} className={classes.flexCenter}>
          <Phone style={{ marginRight: '4px' }} />
          <span>{REACT_APP_FOOTER_PHONE}</span>
        </a>
        <small>{process.env.REACT_APP_VERSION}</small>
      </div>
    </footer>
  )
}

export default Footer

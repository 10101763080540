import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { fetchClases, fetchReport } from 'redux/actions'
import moment from 'moment'
import _ from 'lodash'
import { Grid } from '@material-ui/core'
import NextClasses from 'components/NextClasses'
import StudentProgress from 'components/StudentProgress'
import StudentReport from 'components/StudentReport'
import SectionCard from 'components/SectionCard'
import MiniBox from 'components/MiniBox'
import Tip from 'components/Tip'
import Breadcrumb from 'components/Breadcrumbs'
moment.locale('es')
moment.updateLocale('es', {
  monthsShort: moment.monthsShort('-MMM-'),
  weekdaysShort: 'dom_lun_mar_mié_jue_vie_sáb'.split('_'),
  weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
})

const Catedra = props => {
  const { location, match } = props
  const path = location.pathname
  let isLoading = true
  const dispatch = useDispatch()
  const { user, role_active } = useSelector(({ me }) => me, shallowEqual)
  const alumno = role_active === 'alumno'
  const idCatedra = match.params.catedra
  const clasesIds = useSelector(state => state.catedras.byId[idCatedra].clases || [], shallowEqual)
  const report = useSelector(state => state.catedras.byId[idCatedra].report || [], shallowEqual)
  if (clasesIds.length === 0 && report.length === 0) {
    dispatch(fetchClases(user.id, idCatedra, role_active))
    !alumno && dispatch(fetchReport(idCatedra, role_active))
  } else {
    isLoading = false
  }
  const clases = useSelector(state => Object.values(state.clases.byId).filter(clase => clasesIds.includes(clase.id)), shallowEqual)
  const prox = _.filter(clases, item => ['open', 'inProgress', 'pending'].includes(item.estado))

  const perdidas = _.filter(clases, item => ['absent'].includes(item.estado))

  const finalizadas = _.filter(clases, item => ['close', 'cancel'].includes(item.estado))

  const displayDateHours = date =>
    moment
      .utc(date)
      .local()
      .format(process.env.REACT_APP_DATE_FORMAT)
  if (alumno) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <NextClasses {...props} cardTitle={'Próximas Clases'} clases={prox} />
          {perdidas.length > 0 && (
            <SectionCard cardTitle={'Clases Ausentes'}>
              <Grid container spacing={3}>
                {perdidas
                  .sort((a, b) => (a.fecha > b.fecha ? -1 : 1))
                  .slice(0, process.env.REACT_APP_MINIBOX_LIMIT)
                  .map((clase, index) => (
                    <Grid key={index + clase.nombre} item xs={2}>
                      <MiniBox
                        linkto={`${path}/${clase.id}`}
                        heading={clase.nombre}
                        content={displayDateHours(clase.fecha)}
                        backgroundColor="darkgreen"
                      />
                    </Grid>
                  ))}
              </Grid>
            </SectionCard>
          )}
          {finalizadas.length > 0 && (
            <SectionCard cardTitle={'Clases Tomadas'}>
              <Grid container spacing={3}>
                {finalizadas
                  .sort((a, b) => (a.fecha > b.fecha ? -1 : 1))
                  .map((clase, index) => (
                    <Grid key={index + clase.nombre} item xs={2}>
                      <MiniBox
                        linkto={`${path}/${clase.id}`}
                        heading={clase.nombre}
                        content={displayDateHours(clase.fecha)}
                        footer={!clase.iniciada && 'No dicatada'}
                      />
                    </Grid>
                  ))}
              </Grid>
            </SectionCard>
          )}
        </Grid>
        <Grid item xs={3}>
          <StudentProgress cardTitle={'Tu Progreso'} clases={clases} />
        </Grid>
      </Grid>
    )
  } else {
    return (
      <>
        <Breadcrumb {...props} />
        <Tip />
        <NextClasses {...props} cardTitle={'Próximas Clases'} clases={prox} />
        {finalizadas.length > 0 && (
          <SectionCard cardTitle={'Clases Dictadas'}>
            <Grid container spacing={3}>
              {finalizadas
                .sort((a, b) => (a.fecha > b.fecha ? -1 : 1))
                .map((clase, index) => (
                  <Grid key={index + clase.nombre} item xs={2}>
                    <MiniBox
                      linkto={`${path}/${clase.id}`}
                      heading={clase.nombre}
                      content={displayDateHours(clase.fecha)}
                      footer={clase.estado === 'cancel' && 'No dictada'}
                    />
                  </Grid>
                ))}
            </Grid>
          </SectionCard>
        )}
        {!isLoading && <StudentReport report={report} />}
      </>
    )
  }
}

export default Catedra

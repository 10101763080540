import React from 'react'
import _ from 'lodash'

import { Typography, LinearProgress, Box, Paper, Grid } from '@material-ui/core'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'

import { SentimentDissatisfied, SentimentSatisfied, SentimentVerySatisfied } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 25,
    marginTop: 12,
    padding: '3%',
  },
  cardTitle: {
    margin: '0',
    paddingBottom: 10,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  legend: {
    marginTop: theme.spacing(4),
  },
  icons: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(6),
    fontSize: 64,
  },
}))

const BorderLinearProgress = withStyles(({ palette, spacing }) => ({
  root: {
    height: 10,
    backgroundColor: '#f0f0f0',
  },
  bar: {
    borderRadius: 20,
    backgroundColor: palette.auxiliar.darkgreen.regular,
  },
}))(LinearProgress)

const StudentProgress = ({ cardTitle, clases }, props) => {
  const classes = useStyles(props)
  const theme = useTheme()
  const tomadas = _.filter(clases, item => ['close'].includes(item.estado)).length
  const perdidas = _.filter(clases, item => ['absent'].includes(item.estado)).length
  const pendientes = _.filter(clases, item => ['pending'].includes(item.estado)).length
  const activas = _.filter(clases, item => ['open', 'inProgress'].includes(item.estado)).length
  const progress = clases.length && Math.round((tomadas / clases.length) * 100)
  const score = perdidas === 0 ? 100 : perdidas <= 1 ? 50 : 0
  const status = (score => (score >= 40 && score <= 60 ? 'regular' : score < 40 ? 'bad' : 'good'))(score)
  const colors = {
    bad: theme.palette.auxiliar.red.regular,
    regular: theme.palette.auxiliar.orange.regular,
    good: theme.palette.auxiliar.darkgreen.regular,
  }
  const color = colors[status]
  const icons = {
    bad: <SentimentDissatisfied className={classes.icons} style={{ color: color }} />,
    regular: <SentimentSatisfied className={classes.icons} style={{ color: color }} />,
    good: <SentimentVerySatisfied className={classes.icons} style={{ color: color }} />,
  }
  const texts = {
    bad: `Perdiste ${perdidas} clases`,
    regular: 'Tienes una clase perdida',
    good: 'Muy buen trabajo estas al día',
  }

  return (
    <>
      <Paper className={classes.root}>
        <Box className={classes.cardTitle} fontFamily="Fjalla One" fontSize="h6.fontSize" m={1}>
          {cardTitle}
        </Box>

        <Grid container spacing={3} className={classes.classGrid}>
          <Grid item xs={12}>
            <Grid container direction="column" justify="center" alignItems="center">
              <Typography variant="h6" className={classes.legend}>
                {texts[status]}
              </Typography>
              {icons[status]}
            </Grid>

            <BorderLinearProgress className={classes.margin} variant="determinate" value={progress} />
            <Box mt={2}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>Clases tomadas:</Grid>
                <Grid item>
                  {tomadas}/{clases.length}
                </Grid>
              </Grid>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>Clases pendientes:</Grid>
                <Grid item>
                  {pendientes}/{clases.length}
                </Grid>
              </Grid>
              {perdidas !== 0 && (
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item>Clases perdidas:</Grid>
                  <Grid item>{perdidas}</Grid>
                </Grid>
              )}
              {activas !== 0 && (
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item>Clases en curso:</Grid>
                  <Grid item>{activas}</Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

StudentProgress.defaultProps = {}

export default StudentProgress

export default {
  error: {
    claseEnd: 'Error al terminar la clase.',
    claseExtend: 'Error al extender la clase.',
    claseInit: 'Error al iniciar la clase.',
    mixedInit: 'Error al iniciar la clase.',
    getAccesos: 'Error solicitando accesos.',
    getCatedras: 'Error solicitando cátedras.',
    getClase: 'Error solicitando clase.',
    getClases: 'Error solicitando clases.',
    getEntornosAlumno: 'Error solicitando entornos.',
    getMisEntornos: 'Error solicitando Mis Entornos.',
    getMyConsoleAccess: 'Error solicitando Mi Acceso a Consola',
    getSidebar: 'Error solicitando Menú.',
    getUsuarios: 'Error solicitando usuarios.',
    getReport: 'Error solicitando el reporte.',
    misEntornosInit: 'Error al iniciar entornos.',
    rollbackInit: 'Error al aplicar rollback/s.',
    initMyConsoleAccess: 'Error al iniciar accessos a la consola.',
    getRecursos: 'Error al iniciar la clase, los recursos están vacíos.',
  },
  success: {
    claseEnd: 'Aguarde, terminando la clase.',
    claseExtend: 'Aguarde, extendiendo la clase.',
    claseInit: 'Aguarde, iniciando la clase.',
    initMyConsoleAccess: 'Aguarde, iniciando mis accessos.',
    misEntornosInit: 'Aguarde, iniciando mis entornos.',
    mixedInit: 'Aguarde, iniciando la clase.',
    rollbackInit: 'Aguarde, iniciando rollback.',
  },
  warning: {
    recursos: 'No se puede iniciar la clase, se debe de seleccionar al menos un recurso.',
  },
  info: {},
}

import { RECEIVE_REPORT, SET_REPORT_FILTER } from 'actionTypes'

const initialState = {
  byEndpoint: {},
  allReports: [],
  filtersByName: {},
  activeFilters: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_REPORT:
      return {
        ...state,
        byEndpoint: {
          ...state.byEndpoint,
          [action.data.endpoint]: action.data.report,
        },
        allReports: state.allReports.includes(action.data.endpoint)
          ? [...state.allReports]
          : [...state.allReports, action.data.endpoint],
      }
    case SET_REPORT_FILTER:
      return {
        ...state,
        filtersByName: {
          ...state.filtersByName,
          [action.data.name]: action.data,
        },
        activeFilters: state.activeFilters.includes(action.data.name)
          ? [...state.activeFilters]
          : [...state.activeFilters, action.data.name],
      }

    default:
      return state
  }
}

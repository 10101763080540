import { FETCH_ENTORNOS, UPDATE_CLASE, TOGGLE_ACTIVE_ENTORNO, SELECT_SNAPSHOT, REMOVE_SELECTED_SNAPSHOT } from 'actionTypes'
import { enqueueSnackbar } from 'redux/actions'
import messages from 'messages'

const { REACT_APP_API_END } = process.env

const storeData = (type, data) => ({ type, data })

export const fetchMisEntornos = (userId, catedraId, clase_id) => dispatch => {
  fetch(REACT_APP_API_END + `/entornoGetAllByCatedra?usuario_id=${userId}&catedra_id=${catedraId}&clase_id=${clase_id}`, {
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
    }),
  })
    .then(res => res.json())
    .then(res => {
      dispatch(storeData(FETCH_ENTORNOS, res))
      dispatch(storeData(UPDATE_CLASE, { id: clase_id, data: { misEntornos: res.map(entornos => entornos.id) } }))
    })
    .catch(err =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.getMisEntornos,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

export const fetchAlumnoEntornos = (userId, catedraId, clase_id, role, alumno_id) => dispatch => {
  dispatch(storeData(UPDATE_CLASE, { id: clase_id, data: { alumnoEntornos: [] } }))
  fetch(
    REACT_APP_API_END +
      `/entornoGetAllByCatedra?usuario_id=${userId}&usuario_role=${role}&catedra_id=${catedraId}&clase_id=${clase_id}&alumno_id=${alumno_id}`,
    {
      headers: new Headers({
        Authorization: sessionStorage.getItem('token'),
      }),
    }
  )
    .then(res => res.json())
    .then(res => {
      dispatch(storeData(FETCH_ENTORNOS, res))
      dispatch(storeData(UPDATE_CLASE, { id: clase_id, data: { alumnoEntornos: res.length ? res.map(entornos => entornos.id) : [] } }))
    })
    .catch(err =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.getEntornosAlumno,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

export const toggleActiveEntorno = id => dispatch => dispatch(storeData(TOGGLE_ACTIVE_ENTORNO, { id }))

export const selectSnapshot = (entornoId, snapshotId) => dispatch => dispatch(storeData(SELECT_SNAPSHOT, { entornoId, snapshotId }))

export const removeSelectedSnapshot = (entornoId, snapshotId) => dispatch => dispatch(storeData(REMOVE_SELECTED_SNAPSHOT, { entornoId, snapshotId }))

export const resetearAccesos = (usuario_id, rollbackArray) => dispatch => {
  fetch(REACT_APP_API_END + `/entornoRollback`, {
    method: 'POST',
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ usuario_id, rollback: rollbackArray }),
  })
    .then(res => res.json())
    .then(res => {
      dispatch(
        enqueueSnackbar({
          message: messages.success.rollbackInit,
          options: {
            variant: 'success',
          },
        })
      )
    })
    .catch(err =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.rollbackInit,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

import React, { useState, useEffect } from 'react'
import { useInterval } from 'customHooks/useInterval'
import { debounce, isEmpty } from 'lodash'

import {
  startClase,
  startClaseAlumnoEntornos,
  deleteConsoleInfo,
  fetchMyConsoleAccess,
  fetchAllConsoleAccess,
  endClase,
  fetchClaseDetail,
  showModal,
  initMyConsoleAccess,
  initMixed,
  enqueueSnackbar
} from 'redux/actions'
import { connect, useDispatch, shallowEqual, useSelector } from 'react-redux'

import { Grid, Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Entornos from 'components/Entornos'
import ReporteAccessos from 'components/ReporteAccesos'
import SectionCard from 'components/SectionCard'
import MiniBox from 'components/MiniBox'
import Loading from 'components/Loading'
import ClassProgress from 'components/ClassProgress'
import Breadcrumb from 'components/Breadcrumbs'

import moment from 'moment'
import PdfDisplay from 'components/PdfDisplay'
import ConsoleAccess from 'components/ConsoleAccess'
import { ACCESO_A_CONSOLA, TRADITIONAL, MIXED, ALUMNO, PROFESOR } from 'helpers/constants'

import messages from 'messages'

moment.locale('es')
moment.updateLocale('es', {
  monthsShort: moment.monthsShort('-MMM-'),
  weekdaysShort: 'dom_lun_mar_mié_jue_vie_sáb'.split('_'),
  weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
})

const CONSOLE_CLASS_UNITS = [ACCESO_A_CONSOLA, MIXED]
const ENVIROMENT_CLASS_UNITS = [TRADITIONAL, MIXED]

const useStyles = makeStyles((theme, props) => ({
  button: {
    backgroundColor: (props) => theme.palette[!props.started ? 'primary' : 'secondary'].main,
    color: 'white',
    marginTop: 20,
  },
  iframe: {
    marginTop: '1em',
    width: '100%',
    height: '1000px'
  }
}))

const getGuiaNameFromLink = (guiaLink) => {
  const splitedLink = guiaLink.split('/')

  const fullFileName = splitedLink[splitedLink.length - 1]

  const [fileNameWithSlashes] = fullFileName.split('.')

  const fileName = fileNameWithSlashes.replaceAll('-', ' ')

  return fileName
}

const Clase = (props) => {
  const {
    clase,
    isInProgressClass,
    match: { params },
    me: { role_active, user },
  } = props
  const isAlumno = role_active === ALUMNO
  let catedraId = params.catedra
  let claseId = params.claseId
  const dispatch = useDispatch()
  const isConsoleClass = clase && CONSOLE_CLASS_UNITS.includes(clase.unidad)
  const isEnviromentsClass = clase && ENVIROMENT_CLASS_UNITS.includes(clase.unidad)
  const consoleAccess = useSelector(({ consolas: { myConsoleAccess } }) => myConsoleAccess, shallowEqual)
  const hasMyConsoleAccess = !isEmpty(consoleAccess) && consoleAccess.password
  const [started, setStart] = useState(isInProgressClass)
  const [starting, setStarting] = useState(isInProgressClass ? isInProgressClass : false)
  const [isFinishedClass, setIsFinishedClass] = useState(false)

  useEffect(() => setStart(isInProgressClass), [isInProgressClass])

  useEffect(() => {
    dispatch(fetchClaseDetail(user.id, catedraId, claseId, role_active))
    // eslint-disable-next-line
  }, [isFinishedClass])

  const allowCronMyConsoleAccess = isConsoleClass && isInProgressClass && !hasMyConsoleAccess
  const allowCronAllConsoleAccess = isConsoleClass && isInProgressClass && role_active === PROFESOR

  useInterval(
    () => {
      getConsoleAccessByType('all')
    },
    allowCronAllConsoleAccess ? 10000 : null
  )

  useInterval(
    () => {
      getConsoleAccessByType('mine')
    },
    allowCronMyConsoleAccess ? 10000 : null
  )

  const getConsoleAccessByType = (type) => {
    if (clase && clase.iniciada) {
      const requestParams = {
        userId: user.id,
        claseId: clase.id,
        catedraId,
        role: role_active,
        unidad: clase.unidad,
      }

      return {
        all: () => dispatch(fetchAllConsoleAccess(requestParams)),
        mine: () => dispatch(fetchMyConsoleAccess(requestParams)),
      }[type]()
    }
  }

  const displayDateHours = (date) => moment.utc(date).local().format(process.env.REACT_APP_DATE_FORMAT)
  const displayClassProgress = isInProgressClass ? (
    <ClassProgress
      isFinishedClass={isFinishedClass}
      setIsFinishedClass={setIsFinishedClass}
      {...props}
      user={user}
      catedraId={catedraId}
      role_active={role_active}
    />
  ) : null

  const handlerStartClase = debounce(() => {
    const paramsAlumno = { userId: user.id, claseId: clase.id, catedraId, role: role_active }
    const paramsProfesor = {
      userId: user.id,
      claseId: clase.id,
      recursos: props.misEntornos.reduce((acum, { activo, recurso_id }) => {
        return activo ? [...acum, recurso_id] : acum
      }, []),
      catedraId,
      role: role_active,
      unidad: clase.unidad,
    }

    const studentInitClassDic = {
      [ACCESO_A_CONSOLA]: initMyConsoleAccess,
      [TRADITIONAL]: startClaseAlumnoEntornos,
      [MIXED]: initMixed
    }

    const startActionMap = {
      [ALUMNO]: () => studentInitClassDic[clase.unidad](paramsAlumno),
      [PROFESOR]: () => startClase(paramsProfesor),
    }

    if (!started) {
      const handleStart = startActionMap[role_active]
      
      if (role_active === PROFESOR && !paramsProfesor.recursos.length && clase.unidad !== ACCESO_A_CONSOLA) {
        dispatch(
          enqueueSnackbar({
            message: messages.warning.recursos,
            options: {
              variant: 'warning',
            },
          })
        )
      }
      else{
        dispatch(handleStart())
        setStarting(true)
      }

    }
  }, 1000)

  const handleResourceCleaning = () => {
    if (isConsoleClass) dispatch(deleteConsoleInfo)
  }

  const handlerEndClase = debounce(() => {
    if (started) {
      dispatch(endClase(user.id, clase.id, catedraId, role_active))
      handleResourceCleaning()
    }
  }, 1000)

  const classes = useStyles({ ...props, started })

  const isActive = (clase) => ['open', 'inProgress', 'absent'].includes(clase.estado)

  const startLabel = !started ? (starting ? 'Comenzando' : 'Comenzar') : 'Terminar'

  const startBtn = (role, clase) => {
    let byRolMap = {
      [ALUMNO]: {
        shouldDisplay: ['open', 'absent'].includes(clase.estado),
        handlers: started ? handlerEndClase : handlerStartClase,
      },
      [PROFESOR]: {
        shouldDisplay: ['open', 'inProgress'].includes(clase.estado),
        handlers: started ? handlerEndClase : () => dispatch(showModal('WARNING_INIT_CLASE', { handlerStartClase, clase })),
      },
    }

    return byRolMap[role].shouldDisplay ? (
      <Fab
        onClick={byRolMap[role].handlers}
        variant="extended"
        size="medium"
        aria-label="Add"
        color="primary"
        className={classes.button}
      >
        {startLabel}
      </Fab>
    ) : null
  }

  return clase === undefined ? (
    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Loading color={'primary'} />
    </div>
  ) : (
    <>
      <Breadcrumb {...props} />
      <SectionCard noContentPadding>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <div>
              <MiniBox
                heading={clase.nombre}
                content={displayDateHours(clase.fecha)}
                backgroundColor={isActive(clase) ? 'darkgreen' : 'gray'}
              />
            </div>
            {startBtn(role_active, clase)}
          </Grid>
          <Grid item xs={10}>
            {displayClassProgress}
          </Grid>
        </Grid>
      </SectionCard>
      {isConsoleClass &&
        <SectionCard cardTitle={'Accesos'}>
          <ConsoleAccess access={consoleAccess} isLoadingAccess={isInProgressClass && !hasMyConsoleAccess} />
        </SectionCard>
      }
      {isEnviromentsClass &&
        <Entornos
          heading={'Mis Entornos'}
          noDisplayCols={isAlumno ? ['¿Encender?', 'Rollback'] : []}
          clase={clase}
          claseId={params.claseId}
          catedraId={params.catedra}
          me={props.me}
        />
      }
      {!isAlumno && <ReporteAccessos isConsoleClass={isConsoleClass} {...props} />}
      {clase && clase.guia && (
        <SectionCard cardTitle={getGuiaNameFromLink(clase.guia)}>
          {clase.guia && <iframe className={classes.iframe} title="guia" src={clase.guia} />}
        </SectionCard>
      )}
    </>
  )
}

const selectClase = (state, claseId) => state.clases.byId[claseId]
const getClassTotalTime = ({ extra_duracion, duracion_clase }) =>
  extra_duracion ? duracion_clase + extra_duracion : duracion_clase
const selectMisEntornos = (state, clasId) => {
  const emptyClase = !isEmpty(state.clases.byId[clasId]) && !isEmpty(state.clases.byId[clasId].misEntornos)

  console.info('[pages/Clase.selectMisEntornos] Clase by id:', state.clases.byId[clasId])

  return emptyClase
    ? state.clases.byId[clasId].misEntornos.map((entornoId) => state.entornos.byId[entornoId])
    : []
  }
const getInProgressClass = (clase) => clase.estado === 'inProgress'

const mapStateToProps = (state, ownProps) => {
  const {
    match: { params },
  } = ownProps

  const clase = selectClase(state, params.claseId)

  console.info('mis entornos', selectMisEntornos(state, params.claseId))
  if (clase) {
    return {
      me: state.me,
      clase,
      misEntornos: selectMisEntornos(state, params.claseId),
      isInProgressClass: getInProgressClass(clase),
      classTotalTime: getClassTotalTime(clase),
      classTotalTimeInSeconds: getClassTotalTime(clase) * 60,
    }
  } else {
    return {
      me: state.me,
      clase: clase,
      misEntornos: selectMisEntornos(state, params.claseId),
    }
  }
}

export default connect(mapStateToProps, null)(Clase)

import React from 'react'
import { fetchReports } from 'redux/actions'

import SectionCard from 'components/SectionCard'
import ReportTableLayout from 'components/ReportTableLayout'

import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { useIntl } from 'react-intl'

/* cols */
import ClassAttendanceByDate from 'components/cols/reports/ClassAttendanceByDate'
import StudentAttendance from 'components/cols/reports/StudentAttendance'
import StudentBonusAttendance from 'components/cols/reports/StudentBonusAttendance'

const Reports = ({
  match: {
    params: { report },
  },
}) => {
  const ReportMap = {
    ClassAttendance: {
      Component: ReportTableLayout,
      fetch: { action: fetchReports, endpoint: 'reportsGetClassAttendanceByDate' },
      columns: ClassAttendanceByDate,
      filters: {},
    },
    ClassExternalAttendance: {
      Component: ReportTableLayout,
      fetch: { action: fetchReports, endpoint: 'reportsGetClassAttendanceByDate' },
      columns: ClassAttendanceByDate,
      filters: { field: 'dentro_de_clase', value: false },
    },
    StudentAttendance: {
      Component: ReportTableLayout,
      fetch: { action: fetchReports, endpoint: 'reportsGetStudentAttendanceByDate' },
      columns: StudentAttendance,
      filters: {},
    },
    StudentBonusAttendance: {
      Component: ReportTableLayout,
      fetch: { action: fetchReports, endpoint: 'reportsGetStudentBonusAttendanceByDate' },
      columns: StudentBonusAttendance,
      filters: {},
    },
  }

  const intl = useIntl()

  const ReportComponent = ({ Component, fetch, ...rest }) => {
    const { filters } = ReportMap[report]
    const { action, endpoint } = ReportMap[report].fetch

    const data = useSelector((state) => state.reports.byEndpoint[endpoint] || [])

    const { field, value } = !isEmpty(filters) && ReportMap[report].filters
    const filteredData = data.filter((row) => row[field] === value)

    return <Component fetchAction={action} endpoint={endpoint} {...rest} data={!isEmpty(filters) ? filteredData : data} />
  }

  return (
    <SectionCard cardTitle={intl.formatMessage({ id: `sidebar.${report}` })}>
      <ReportComponent report={report} {...ReportMap[report]} />
    </SectionCard>
  )
}

export default Reports

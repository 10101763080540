import React from 'react'

import LinearProgressMaterial from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  root: {
    backgroundColor: (props) => `${theme.palette.auxiliar[props.colorState].accent}`,
    height: 10,
    borderRadius: 10,
    flex: 0.6,
  },
  bar: {
    borderRadius: 10,
    backgroundColor: (props) => `${theme.palette.auxiliar[props.colorState].dark}`,
  },
  textValue: {
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    flex: 0.3,
  },
}))

const LinearProgress = (props) => {
  const { colorState } = props
  console.log('λ: LinearProgress -> props', props)

  const classes = useStyles({ colorState })
  return (
    <div className={classes.container}>
      <LinearProgressMaterial
        classes={{ root: classes.root, bar: classes.bar }}
        variant="determinate"
        color="secondary"
        {...props}
      />
      <Typography className={classes.textValue}>{props.value + ' %'}</Typography>
    </div>
  )
}

export default LinearProgress

import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 0 8px 0',
    justifyContent: 'center',
    flexWrap: 'wrap',
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
  currentPath: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: 'Roboto Condensed',
  },
}))

const Breadcrumb = props => {
  const {
    carreraDisplayName,
    materiaName,
    catedraDisplayName,
    claseDisplayName,
    match: { params },
    location: { pathname },
  } = props
  const classes = useStyles()

  return (
    <>
      <Breadcrumbs separator={<ChevronRight style={{ fontSize: 18 }} />} aria-label="Breadcrumb" className={classes.root}>
        <Link color="inherit" to="/">
          Panel de Control
        </Link>
        <Link color="inherit" to={pathname}>
          {carreraDisplayName}
        </Link>
        <Link color="inherit" to={pathname}>
          {materiaName}
        </Link>
        {Object.keys(params).map((param, index) => (
          <Link key={param + index} color="inherit" to={param === 'catedra' ? `/catedra/${params[param]}` : pathname}>
            {param === 'catedra' ? catedraDisplayName : claseDisplayName}
          </Link>
        ))}
      </Breadcrumbs>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { catedra, claseId },
    },
  } = ownProps

  return {
    carreraDisplayName: (state.catedras && state.catedras.byId[catedra].carrera) || '',
    materiaName: state.catedras.byId[catedra].titulo || '',
    catedraDisplayName: state.catedras.byId[catedra].subtitulo || '',
    ...(state.clases.byId[claseId] && { claseDisplayName: state.clases.byId[claseId].nombre }),
  }
}

export default connect(
  mapStateToProps,
  null
)(Breadcrumb)

import { UPDATE_CATEDRA, FETCH_CLASES, FETCH_CLASE_DETAIL, RESET_CLASES } from 'actionTypes'
import { fetchMisEntornos, enqueueSnackbar } from 'redux/actions'
import messages from 'messages'

const { REACT_APP_API_END } = process.env
const storeData = (type, data) => ({ type, data })
const ERROR_RECURSOS = 'fail to start, empty resources'

export const fetchClases = (userId, catedraId, role) => (dispatch) => {
  fetch(REACT_APP_API_END + `/claseGetAllByCatedra?usuario_id=${userId}&catedra_id=${catedraId}&usuario_role=${role}`, {
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch(storeData(UPDATE_CATEDRA, { id: catedraId, data: { clases: res.map((clase) => clase.id) } }))
      dispatch(storeData(FETCH_CLASES, res))
    })
    .catch((err) =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.getClases,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

export const fetchClaseDetail = (userId, catedraId, claseId, role) => (dispatch) => {
  fetch(REACT_APP_API_END + `/claseGetById?usuario_id=${userId}&usuario_role=${role}&clase_id=${claseId}`, {
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch(storeData(UPDATE_CATEDRA, { id: catedraId, clases: res.id }))
      dispatch(storeData(FETCH_CLASE_DETAIL, { id: claseId, res }))
    })
    .catch((err) =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.getClase,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

export const startClase = ({ userId, claseId, recursos, catedraId, role, unidad }) => (dispatch) =>
  fetch(REACT_APP_API_END + `/claseIniciar`, {
    method: 'POST',
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      usuario_id: userId,
      clase_id: claseId,
      recursos: recursos,
      catedra_id: catedraId,
      unidad,
    }),
  })
    .then( response => {
      if(!response.ok) throw new Error(response.error)
      dispatch(fetchClaseDetail(userId, catedraId, claseId, role))
      dispatch(
        enqueueSnackbar({
          message: messages.success.claseInit,
          options: {
            variant: 'success',
          },
        })
      )
      setTimeout(() => {
        dispatch(fetchMisEntornos(userId, catedraId, claseId))
      }, 15000)
    })
    .catch((err) => {
      const errorMessage = err.message == ERROR_RECURSOS ? messages.error.getRecursos : messages.error.claseInit
      dispatch(
        enqueueSnackbar({
          message: errorMessage,
          options: {
            variant: 'error',
          },
        })
      )
    })

export const startClaseAlumnoEntornos = ({ userId, claseId, catedraId, role }) => (dispatch) => {
  fetch(REACT_APP_API_END + `/entornoIniciar`, {
    method: 'POST',
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      usuario_id: userId,
      clase_id: claseId,
      usuario_role: role,
    }),
  })
    .then((res) => res.json())
    .then(() => {
      dispatch(fetchClaseDetail(userId, catedraId, claseId, role))
      dispatch(
        enqueueSnackbar({
          message: messages.success.misEntornosInit,
          options: {
            variant: 'success',
          },
        })
      )
      setTimeout(() => {
        dispatch(fetchClaseDetail(userId, catedraId, claseId, role))
        dispatch(fetchMisEntornos(userId, catedraId, claseId))
      }, 10000)
    })
    .catch((err) =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.misEntornosInit,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

export const endClase = (userId, claseId, catedraId, role) => (dispatch) => {
  fetch(REACT_APP_API_END + `/claseTerminar`, {
    method: 'POST',
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      usuario_id: userId,
      clase_id: claseId,
    }),
  })
    .then((res) => res.json())
    .then(() => {
      dispatch(
        enqueueSnackbar({
          message: messages.success.claseEnd,
          options: {
            variant: 'success',
          },
        })
      )
      dispatch(fetchClaseDetail(userId, catedraId, claseId, role))
    })
    .catch((err) =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.claseEnd,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

export const extendClass = (userId, claseId, catedraId, role, extraTime) => (dispatch) => {
  fetch(REACT_APP_API_END + `/claseExtender`, {
    method: 'POST',
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      usuario_id: userId,
      clase_id: claseId,
      tiempo: extraTime,
    }),
  })
    .then((res) => res.json())
    .then(() => {
      dispatch(
        enqueueSnackbar({
          message: messages.success.claseExtend,
          options: {
            variant: 'success',
          },
        })
      )
      dispatch(fetchClaseDetail(userId, catedraId, claseId, role))
    })
    .catch((err) =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.claseExtend,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

export const clasesByDate = (date) => (dispatch) => {
  dispatch(storeData(RESET_CLASES))
  fetch(REACT_APP_API_END + `/claseGetByFecha?fecha=${date}`, {
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch(storeData(FETCH_CLASES, res))
    })
    .catch((err) =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.getClases,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

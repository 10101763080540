import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { showModal } from 'redux/actions'

import { LinearProgress, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import moment from 'moment'
moment.locale('es')
moment.updateLocale('es', {
  monthsShort: moment.monthsShort('-MMM-'),
  weekdaysShort: 'dom_lun_mar_mié_jue_vie_sáb'.split('_'),
  weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
})

const BorderTimeProgress = withStyles(({ palette, spacing }) => ({
  root: {
    height: 10,
    backgroundColor: '#f0f0f0',
  },
  bar: {
    borderRadius: 20,
    backgroundColor: palette.auxiliar.darkgreen.regular,
  },
}))(LinearProgress)

const ClassProgress = (props) => {
  const { classTotalTime, isInProgressClass, clase, setIsFinishedClass, isFinishedClass } = props
  const { extra_duracion, iniciada } = clase
  const dispatch = useDispatch()
  const alumno = props.role_active === 'alumno'

  const [remainingTime, setRemainingTime] = useState(classTotalTime || 0) // in minutes

  useEffect(() => {
    if (!isFinishedClass && remainingTime <= 0) {
      setIsFinishedClass(true)
    }
  }, [isFinishedClass, remainingTime])

  const [progress, setProgress] = useState(0) // in seconds

  /*
  CRON timmer
  */
  const getProgressDiff = (inicio) => moment.utc().diff(moment.utc(inicio), 'seconds')

  const getProgress = () => {
    setProgress(getProgressDiff(iniciada))
    setRemainingTime(classTotalTime - Math.floor(progress / 60))
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (isInProgressClass) {
      let timer = setInterval(() => getProgress(), 1000)
      return () => clearInterval(timer)
    } else {
      setProgress(0)
      setRemainingTime(0)
    }
  })

  /*
  DISPLAY PERCENT TIME 
  */
  let progressPercent = Math.floor((100 / (classTotalTime * 60)) * progress)

  /* show modal condicionaly */
  const [extended, setShowExtended] = useState(false)

  if (!extra_duracion && !extended && isInProgressClass && remainingTime === 5 && !alumno) {
    dispatch(
      showModal('EXTENDER_CLASE', {
        userId: props.user.id,
        claseId: props.clase.id,
        catedraId: props.catedraId,
        role: props.role_active,
      })
    )
    setShowExtended(true)
  }

  return (
    <>
      <p style={{ marginTop: 0 }}>Progreso de la Clase</p>
      <BorderTimeProgress variant="determinate" value={progressPercent > 100 ? 100 : progressPercent} />
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>{progress && moment().hour(0).minute(0).second(progress).format('HH : mm : ss')}</Grid>
        <Grid item>{classTotalTime && moment().hour(0).minute(classTotalTime).second(0).format('HH : mm : ss')}</Grid>
      </Grid>
    </>
  )
}
export default ClassProgress

import React, { useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useInterval } from 'customHooks/useInterval'

import MaterialTable from 'material-table'
import SectionCard from './SectionCard'
import usuarioAccesosCols from './cols/UsuariosAccesos'
import { ACCESO_A_CONSOLA, TRADITIONAL, MIXED } from '../helpers/constants'

/* Redux */
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchAccesos, showModal, fetchUsuariosByClase } from 'redux/actions'

const ReporteAccesos = (props) => {
  const {
    accesos,
    clase,
    header,
    me,
    isConsoleClass,
    isInProgressClass,
    usuariosByClase,
    noDisplayCols,
    me: { role_active, user },
  } = props

  const accesses = useSelector(({ consolas: { byUserId } }) => byUserId && Object.values(byUserId), shallowEqual)

  const theme = useTheme()
  const dispatch = useDispatch()

  const accesosMap = {
    admin: {
      fetch: fetchUsuariosByClase(clase.id),
      data: usuariosByClase,
    },
    profesor: {
      fetch: fetchAccesos(user.id, role_active, clase.id),
      data: isConsoleClass ? accesses : accesos,
    },
  }

  useEffect(() => {
    dispatch(accesosMap[role_active].fetch)
    // eslint-disable-next-line
  }, [clase.id])

  const cronAccesos = () => {
    if (isInProgressClass) {
      dispatch(accesosMap[role_active].fetch)
    }
  }

  useInterval(cronAccesos, isInProgressClass ? 60000 : null)

  const handlerShowModalEntornos = (rowData) => {
    dispatch(
      showModal('ENTORNOS_ALUMNO', {
        noDisplayCols,
        claseId: clase.id,
        me,
        catedraId: clase.catedra_id,
        clase,
        entornosById: rowData.id,
        acceso: rowData,
      })
    )
  }

  const columns = usuarioAccesosCols(theme, role_active, handlerShowModalEntornos, clase).filter((column) =>
    getColumnsByUnidad(clase.unidad).includes(column.field)
  )

  return (
    <MaterialTable
      isLoading={!accesos.length}
      Toolbar={null}
      columns={columns}
      data={accesosMap[role_active].data}
      options={{
        loadingType: 'linear',
        toolbar: false,
        search: false,
        actionsColumnIndex: -1,
        rowStyle: (rowData) => ({
          backgroundColor: rowData.tableData.id % 2 === 0 ? theme.palette.grey[100] : theme.palette.background.paper,
        }),
        headerStyle: {
          padding: '.5rem',
          fontSize: '.9rem',
          fontFamily: theme.typography.fontFamily,
          fontWeight: '700',
          textTransform: 'uppercase',
          letterSpacing: 0,
          color: theme.text.primary,
        },
      }}
      components={{
        Container: ({ children }) => (
          <SectionCard noContentPadding cardTitle={header || 'Reporte Accesos'}>
            {children}
          </SectionCard>
        ),
      }}
    />
  )
}

const selectAccesos = (state) => Object.keys(state.accesos.byId).map((alumnoId) => state.accesos.byId[alumnoId]) || []
const selectUsuariosByClase = (state) => state.accesos.idsUsuariosByClase.map((usuarioId) => state.accesos.byId[usuarioId])

const mapStateToProps = (state, ownProps) => {
  return {
    accesos: selectAccesos(state),
    usuariosByClase: selectUsuariosByClase(state),
  }
}

export default connect(mapStateToProps, null)(ReporteAccesos)

const getColumnsByUnidad = (unidad) => {
  const genericColumns = ['nombre', 'online', 'rol']
  const consoleColumns = ['account_number', 'user', 'password']
  const traditionalColumns = ['acceso']

  const tableMap = {
    [ACCESO_A_CONSOLA]: [...genericColumns, ...consoleColumns],
    [TRADITIONAL]: [...genericColumns, ...traditionalColumns],
    [MIXED]: [...genericColumns, ...consoleColumns, ...traditionalColumns]
  }

  return tableMap[unidad]
}

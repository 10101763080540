import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import MaterialTable from 'material-table'
import SectionCard from './SectionCard'
import { useTheme } from '@material-ui/core/styles'
import clasesCols from './cols/Clases'
import queryString from 'query-string'
import { KeyboardDatePicker } from '@material-ui/pickers'

import { useInterval } from 'customHooks/useInterval'

import moment from 'moment'
import { clasesByDate } from 'redux/actions'
import MultiDatePicker from './MultiDatePicker'
moment.locale('es')
moment.updateLocale('es', {
  weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
})

export default function ClasesTable(props) {
  const { location, history } = props
  const { fecha } = queryString.parse(location.search)

  const theme = useTheme()
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = React.useState(fecha)

  const clases = useSelector(({ clases }) => Object.values(clases.byId), shallowEqual)
  const modal = useSelector(({ modal }) => modal, shallowEqual)

  const handleSelectClase = (claseId) => {
    const query = queryString.stringify({
      fecha,
      selectedClase: claseId,
    })

    history.push({
      pathname: '',
      search: query,
    })
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
    const query = queryString.stringify({
      fecha: moment(date).utc().format('YYYY-MM-DD'),
    })
    history.push({
      pathname: '',
      search: query,
    })
  }

  const currentHour = moment().hour()

  const clasesType = {
    fetch: clasesByDate(moment(fecha).add(currentHour, 'hour').utc().format()),
    columns: clasesCols(theme, { handleSelectClase }),
    data: clases.filter((clase) => clase.tipo !== 'b'),
  }

  useInterval(
    () => {
      if (!modal.modalType) {
        dispatch(clasesType.fetch)
      }
    },
    fecha ? 60000 : null
  )

  useEffect(() => {
    fecha && dispatch(clasesType.fetch)
    setSelectedDate(moment(fecha).utc().format())
  }, [fecha])

  return (
    <MaterialTable
      isLoading={!clasesType.data.length}
      onRowClick={(event, rowData) => handleSelectClase(rowData.id)}
      Toolbar={null}
      columns={clasesType.columns}
      data={clasesType.data}
      options={{
        loadingType: 'linear',
        toolbar: false,
        search: false,
        actionsColumnIndex: -1,
        rowStyle: (rowData) => ({
          backgroundColor: rowData.tableData.id % 2 === 0 ? theme.palette.grey[100] : theme.palette.background.paper,
        }),
        headerStyle: {
          padding: '.5rem',
          fontSize: '.9rem',
          fontFamily: theme.typography.fontFamily,
          fontWeight: '700',
          textTransform: 'uppercase',
          letterSpacing: 0,
          color: theme.text.primary,
        },
      }}
      components={{
        Container: (props) => {
          return (
            <SectionCard
              noContentPadding
              cardTitle={`Clases del Día ${moment(fecha, 'YYYY-MM-DD').utc().format('dddd D/M/YYYY')}`}
            >
              <div>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  id="date-picker-inline"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />{' '}
              </div>
              {props.children}
            </SectionCard>
          )
        },
      }}
    />
  )
}

import _ from 'lodash'
import { FETCH_CLASE_DETAIL, FETCH_CLASES, UPDATE_CLASE, RECEIVE_USER_ROLE, RESET_CLASES } from 'actionTypes'

const INIT_STATE = {
  byId: {},
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CLASES:
      return {
        ...state,
        byId: {
          ...state.byId,
          ..._.mapKeys(action.data, 'id'),
        },
      }
    case FETCH_CLASE_DETAIL:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: _.merge(state.byId[action.data.id], action.data.res),
        },
      }

    case UPDATE_CLASE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: _.assign({}, state.byId[action.data.id], action.data.data),
        },
      }
    case RESET_CLASES: 
      return INIT_STATE
    case RECEIVE_USER_ROLE:
      return INIT_STATE
    default:
      return state
  }
}

import { FETCH_CATEDRAS, UPDATE_CATEDRA, RECEIVE_USER_ROLE } from 'actionTypes'
import _ from 'lodash'
const INIT_STATE = {
  byId: {},
}
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CATEDRAS:
      return { ...state, byId: { ..._.mapKeys(action.data, 'id') } }
    case UPDATE_CATEDRA:
      let clone = _.cloneDeep(state)
      clone.byId[action.data.id] = { ...clone.byId[action.data.id], ...action.data.data }
      return clone
    case RECEIVE_USER_ROLE:
      return INIT_STATE
    default:
      return state
  }
}

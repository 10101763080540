import _ from 'lodash'
import { FETCH_ENTORNOS, RECEIVE_USER_ROLE, TOGGLE_ACTIVE_ENTORNO, SELECT_SNAPSHOT, REMOVE_SELECTED_SNAPSHOT } from 'actionTypes'

const INIT_STATE = {
  byId: {},
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ENTORNOS:
      return {
        ...state,
        byId: {
          ...state.byId,
          ..._.mapKeys(action.data, 'id'),
        },
      }
    case TOGGLE_ACTIVE_ENTORNO:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: {
            ...state.byId[action.data.id],
            activo: !state.byId[action.data.id].activo,
          },
        },
      }

    case SELECT_SNAPSHOT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.entornoId]: {
            ...state.byId[action.data.entornoId],
            selectedSnapshot: { entorno_id: action.data.entornoId, snapshot_id: action.data.snapshotId },
          },
        },
      }
    case REMOVE_SELECTED_SNAPSHOT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.entornoId]: _.omit(state.byId[action.data.entornoId], 'selectedSnapshot'),
        },
      }
    case RECEIVE_USER_ROLE:
      return INIT_STATE
    default:
      return state
  }
}

import React from 'react'

/** Material UI  */
import { makeStyles } from '@material-ui/core/styles'
import { FormControlLabel, FormControl, RadioGroup, Radio, Fab, Box, DialogActions } from '@material-ui/core/'

import SectionCard from 'components/SectionCard'

import { useDispatch } from 'react-redux'
import { extendClass } from 'redux/actions'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
  radio: {
    checked: {
      color: 'blue',
    },
  },
}))

const options = [{ value: '10', label: '10 minutos' }, { value: '15', label: '15 minutos' }, { value: 'none', label: 'No, gracias.' }]

const ExtenderClase = props => {
  const { handleClose, userId, claseId, catedraId, role } = props
  const classes = useStyles()

  const [extraTime, setExtraTime] = React.useState('none')

  function handleChange(event) {
    setExtraTime(event.target.value)
  }

  const dispatch = useDispatch()

  function handleOk() {
    extraTime !== 'none' && dispatch(extendClass(userId, claseId, catedraId, role, extraTime))
    handleClose()
  }

  return (
    <SectionCard>
      <FormControl component="fieldset" className={classes.formControl}>
        <Box fontFamily="Fjalla One" fontSize="h5.fontSize" mb={2}>
          ¿Agregar Tiempo?
        </Box>
        <Box fontFamily="Roboto Condensed">
          Quedan 5 (cinco) minutos de clase para los entornos de laboratorio.
          <br />
          Puede agregar 10 minutos o 15 minutos adicionales a la clase seleccionando a continuación:
        </Box>
        <RadioGroup aria-label="extraTime" name="extraTime" className={classes.group} value={extraTime} onChange={handleChange}>
          {options.map((option, i) => (
            <FormControlLabel
              fontFamily="Roboto Condensed"
              key={option.label + i}
              value={option.value}
              control={<Radio className={classes.radio} />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <DialogActions>
        <Fab onClick={handleClose} variant="extended" size="medium" aria-label="Add">
          Cancelar
        </Fab>
        <Fab onClick={handleOk} variant="extended" size="medium" aria-label="Add" color="primary">
          Ok
        </Fab>
      </DialogActions>
    </SectionCard>
  )
}

export default ExtenderClase

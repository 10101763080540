import React from 'react'

/* Redux */
import { connect } from 'react-redux'

/* Modals Comp */
import Modal from 'components/Modal'
import entornosAlumno from './modals/EntornosAlumno'
import ExtenderClase from './modals/ExtenderClase'
import InfoAdicional from './modals/InfoAdicional'
import WarningInitClase from './modals/WarningInitClase'

const ModalDisplay = ({ modal: { modalType, modalProps } }) => {
  const modalComponentsMap = {
    ENTORNOS_ALUMNO: entornosAlumno,
    EXTENDER_CLASE: ExtenderClase,
    INFO_ADICIONAL: InfoAdicional,
    WARNING_INIT_CLASE: WarningInitClase,
  }

  if (!modalType) {
    return null
  }

  const SpecificModal = modalComponentsMap[modalType]
  return (
    <>
      <Modal>
        <SpecificModal {...modalProps} />
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
  }
}

export default connect(
  mapStateToProps,
  null
)(ModalDisplay)

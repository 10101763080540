import React from 'react'
import { Check, Close } from '@material-ui/icons'
import moment from 'moment'

const estadoMap = {
  close: <Close style={{ color: '#c00' }} />,
  open: <Check style={{ color: 'green' }} />,
}
const displayDateHours = date =>
  moment
    .utc(date)
    .local()
    .format(process.env.REACT_APP_DATE_FORMAT)
const clasesCols = (theme, handlers) => [
  {
    title: 'Asignatura',
    field: 'catedra',
    type: 'string',
    cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
  },
  {
    title: 'Clase',
    field: 'nombre',
    type: 'string',
    cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
  },
  {
    title: 'Unidad',
    field: 'unidad',
    type: 'string',
    cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
  },
  {
    title: 'Comienzo',
    field: 'hora_inicio',
    type: 'time',
    cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
    render: rowData => (rowData.hora_inicio ? displayDateHours(rowData.hora_inicio) : ''),
  },
  {
    title: 'Finaliza',
    field: 'hora_fin',
    type: 'time',
    cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
    render: rowData => (rowData.hora_fin ? displayDateHours(rowData.hora_fin) : ''),
  },
  {
    title: 'Entornos',
    field: 'entornos',
    type: 'string',
    cellStyle: { fontFamily: theme.typography.fontFamily, padding: '1rem' },
  },

  {
    title: 'Habilitado',
    field: 'habilitada',
    type: 'string',
    render: rowData => (rowData.estado === 'inProgress' ? estadoMap['open'] : estadoMap['close']),
  },
  {
    title: 'Cursada',
    field: 'cursada',
    type: 'string',
    render: rowData => (rowData.estado === 'inProgress' || rowData.estado === 'close' ? estadoMap['open'] : estadoMap['close']),
  },
]
export default clasesCols

import _ from 'lodash'
import { FETCH_ACCESOS, RECEIVE_USER_ROLE, CLEAR_ACCESOS, FETCH_USUARIOS_BY_CLASE } from 'actionTypes'
const INIT_STATE = {
  byId: {},
  idsUsuariosByClase: [],
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ACCESOS:
      return {
        ...state,
        byId: {
          ...state.byId,
          ..._.mapKeys(action.data, 'id'),
        },
      }

    case FETCH_USUARIOS_BY_CLASE:
      return {
        ...state,
        byId: {
          ...state.byId,
          ..._.mapKeys(action.data, 'id'),
        },
        idsUsuariosByClase: action.data.map(data => data.id),
      }

    case CLEAR_ACCESOS:
      return {
        ...state,
        byId: {
          ...state.byId,
        },
        idsUsuariosByClase: [],
      }

    case RECEIVE_USER_ROLE:
      return INIT_STATE
    default:
      return state
  }
}

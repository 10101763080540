import _ from 'lodash'
import { RECEIVE_MY_CONSOLE_ACCESS, ALL_CONSOLE_ACCESSES } from 'actionTypes'

const INIT_STATE = {
  myConsoleAccess: {},
  byUserId: [],
}

export default (state = INIT_STATE, { type, data }) => {
  switch (type) {
    case RECEIVE_MY_CONSOLE_ACCESS:
      return {
        ...state,
        myConsoleAccess: data,
      }
    case ALL_CONSOLE_ACCESSES:
      return {
        ...state,
        byUserId: {
          ..._.mapKeys(data, 'id'),
        },
      }
    default:
      return state
  }
}

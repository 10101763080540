import { TOGGLE_SIDEBAR, RECEIVE_SIDEBAR_DATA, RECEIVE_USER_ROLE } from 'actionTypes'

const INIT_STATE = {
  open: true,
  tree: [],
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return [...state, { open: !state.open }]
    case RECEIVE_SIDEBAR_DATA:
      return { ...state, tree: action.data }
    case RECEIVE_USER_ROLE:
      return INIT_STATE
    default:
      return state
  }
}

import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from 'actionTypes'

export const enqueueSnackbar = notification => {
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: new Date().getTime(),
    },
  }
}

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
})

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
})

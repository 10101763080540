export default {
  sidebar: {
    controlPanel: 'Control Panel',
    reports: 'Reports',
    ClassAttendance: 'Class Attendance',
    ClassExternalAttendance: 'Class External Attendance',
    StudentAttendance: 'Student Attendance',
    StudentBonusAttendance: 'Student Bonus Attendance',
  },
  dashboard: {
    welcomeHeading: 'Welcome {role}!',
    inProgressSubjects: 'Subjects in progress',
    pastSubjects: { alumno: 'Subjects attended', profesor: 'Taught subjects' },
  },
  reports: {
    filters: {
      week: 'week',
      month: 'month',
      quarter: 'quarter',
    },
  },
}

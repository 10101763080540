import React from 'react'

import LinearProgress from 'components/LinearProgress'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  point: {
    height: 10,
    width: 10,
    backgroundColor: (props) => {
      const colorPath = props.isIn ? 'blue' : 'red'
      return theme.palette.auxiliar[colorPath].regular
    },
    borderRadius: '50%',
    marginRight: 10,
  },
  textValue: {},
}))
const InClass = (props) => {
  const classes = useStyles({ isIn: !!props.isIn })
  let textValue = props.isIn ? 'Dentro' : 'Fuera'
  return (
    <div className={classes.container}>
      <div className={classes.point}></div>
      <Typography className={classes.textValue}>{textValue}</Typography>
    </div>
  )
}

export default [
  {
    title: 'Alumno',
    field: 'alumno',
  },
  { title: 'Seccion', field: 'seccion' },
  { title: 'Clase', field: 'clase' },
  { title: 'Ip', field: 'ip_address', render: ({ ip_address }) => (!!ip_address ? ip_address : '-') },
  {
    title: 'Dentro de clase',
    field: 'dentro_de_clase',
    render: (rowData) => {
      return <InClass isIn={rowData.dentro_de_clase} />
    },
  },
  {
    title: 'Duración de clase',
    field: 'duracion_clase',
  },
  {
    title: 'Duración de alumno',
    field: 'duracion_alumno',
  },
  {
    title: '% de asistencia',
    field: 'completationPercent',
    render: (rowData) => {
      const value = rowData.completationPercent > 100 ? 100 : rowData.completationPercent
      const colorState = value > 75 ? 'green' : value > 40 ? 'yellow' : 'red'

      return <LinearProgress colorState={colorState} value={value} />
    },
  },
]

import { TOGGLE_SIDEBAR, RECEIVE_SIDEBAR_DATA } from 'actionTypes'
import { enqueueSnackbar } from 'redux/actions'
import messages from 'messages'

const { REACT_APP_API_END } = process.env
const storeData = (type, data) => ({ type, data })
export const fetchSidebar = (userId, role) => dispatch => {
  fetch(REACT_APP_API_END + `/catedraGetTreeByUser?usuario_id=${userId}&usuario_role=${role}`, {
    headers: new Headers({
      Authorization: sessionStorage.getItem('token'),
    }),
  })
    .then(res => res.json())
    .then(res => {
      dispatch(storeData(RECEIVE_SIDEBAR_DATA, res))
    })
    .catch(err =>
      dispatch(
        enqueueSnackbar({
          message: messages.error.getSidebar,
          options: {
            variant: 'error',
          },
        })
      )
    )
}

export const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR })
